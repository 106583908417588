body {
    background: #fafafa;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    color: #333;
}

/*定义滚动条高宽及背景 高宽分别对应横竖滚动条的尺寸*/
::-webkit-scrollbar {
    width: 1rem;
    height: 1rem;
    background-color: #f5f5f5;
}

/*定义滚动条轨道 内阴影+圆角*/
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 0.375rem #f5f5f5;
    border-radius: 0.25rem;
    background-color: #f5f5f5;
}

/*定义滑块 内阴影+圆角*/
::-webkit-scrollbar-thumb {
    border-radius: 0.25rem;
    box-shadow: inset 0 0 0.375rem #c9c9c9;
    background-color: #c9c9c9;
}

/* ==========================================================================
Hide ng-cloak on page load, https://docs.angularjs.org/api/ng/directive/ngCloak
========================================================================== */
[ng\:cloak],
[ng-cloak],
[data-ng-cloak],
[x-ng-cloak],
.ng-cloak,
.x-ng-cloak {
    display: none !important;
}

/* ==========================================================================
Developement Ribbon
========================================================================== */
.development {
    position: absolute;
    width: 149px;
    height: 149px;
    top: 0;
    left: 0;
    border: 0;
    z-index: 1000;
    background-image: url("../images/development_ribbon.png");
    background-position: center center;
    background-repeat: no-repeat;
}

/* ==========================================================================
Developement Ribbon
========================================================================== */
.ribbon {
    background-color: #a00;
    box-shadow: 0 0 10px #888;
    left: -3.5em;
    moz-box-shadow: 0 0 10px #888;
    moz-transform: rotate(-45deg);
    ms-transform: rotate(-45deg);
    o-transform: rotate(-45deg);
    overflow: hidden;
    position: absolute;
    top: 40px;
    transform: rotate(-45deg);
    webkit-box-shadow: 0 0 10px #888;
    webkit-transform: rotate(-45deg);
    white-space: nowrap;
    width: 15em;
    z-index: 9999;
    pointer-events: none;
}

.ribbon a {
    border: 1px solid #faa;
    color: #fff;
    display: block;
    font: bold 81.25% 'Helvetica Neue', Helvetica, Arial, sans-serif;
    margin: 1px 0;
    padding: 10px 50px;
    text-align: center;
    text-decoration: none;
    text-shadow: 0 0 5px #444;
    pointer-events: none;
}

/* ==========================================================================
Version number in navbar
========================================================================== */
.navbar-version {
    font-size: 10px;
    color: #ccc
}

/* ==========================================================================
Browser Upgrade Prompt
========================================================================== */
.browserupgrade {
    margin: 0.2em 0;
    background: #ccc;
    color: #000;
    padding: 0.2em 0;
}

/* ==========================================================================
Logo styles
========================================================================== */
.navbar-brand.logo {
    padding: 5px 15px;
}

.logo .logo-img {
    height: 45px;
    display: inline-block;
}

/* ==========================================================================
Main page styles
========================================================================== */
.hero-unit {
    margin: 50px auto 0 auto;
    width: 300px;
    font-size: 18px;
    font-weight: 200;
    line-height: 30px;
    background-color: #eee;
    border-radius: 6px;
    padding: 60px;
}

.hero-unit h1 {
    font-size: 60px;
    line-height: 1;
    letter-spacing: -1px;
}

.hipster {
    display: inline-block;
    width: 347px;
    height: 497px;
    background: url("../images/hipster.png") no-repeat center top;
    background-size: contain;
}

/* wait autoprefixer update to allow simple generation of high pixel density media query */
@media only screen and (-webkit-min-device-pixel-ratio: 2),
only screen and (min--moz-device-pixel-ratio: 2),
only screen and (-o-min-device-pixel-ratio: 2/1),
only screen and (min-device-pixel-ratio: 2),
only screen and (min-resolution: 192dpi),
only screen and (min-resolution: 2dppx) {
    .hipster {
        background: url("../images/hipster2x.png") no-repeat center top;
        background-size: contain;
    }
}

/* ==========================================================================
Generic styles
========================================================================== */
.error {
    color: white;
    background-color: red;
}

.choosedColor {
    background-color: #5bc0de;
}

.pad {
    padding: 10px;
}

.break {
    white-space: normal;
    word-break: break-all;
}

.voffset {
    margin-top: 2px;
}

.voffset1 {
    margin-top: 5px;
}

.voffset2 {
    margin-top: 10px;
}

.voffset3 {
    margin-top: 15px;
}

.voffset4 {
    margin-top: 30px;
}

.voffset5 {
    margin-top: 40px;
}

.voffset6 {
    margin-top: 60px;
}

.voffset7 {
    margin-top: 80px;
}

.voffset8 {
    margin-top: 100px;
}

.voffset9 {
    margin-top: 150px;
}

.search-text {
    color: #484848;
}

.w50 {
    width: 50%;
}

.w60 {
    width: 60%;
}

.w70 {
    width: 70%;
}

.w80 {
    width: 80%;
}

.w85 {
    width: 85%;
}

.w90 {
    width: 90%;
}

.w95 {
    width: 97%;
}

.w100 {
    width: 100%;
}

.w-30 {
    width: 30px;
}

.w-130 {
    width: 130px;
}

.h-45 {
    height: 45px;
}

.h-60 {
    height: 60px;
}

.mb-0 {
    margin-bottom: 0px;
}

.mb-5 {
    margin-bottom: 5px;
}

.mb-7 {
    margin-bottom: 7px;
}

.mb-10 {
    margin-bottom: 10px;
}

.mb-20 {
    margin-bottom: 20px;
}

.mb-25 {
    margin-bottom: 25px;
}

.mb-15 {
    margin-bottom: 15px;
}

.mb-0 {
    margin-bottom: 0px;
}

.pt-10 {
    padding-top: 10px;
}

.pt-0 {
    padding-top: 0px;
}

.pb-0 {
    padding-bottom: 0px;
}

.pt-5 {
    padding-top: 5px;
}

.pt-3 {
    padding-top: 3px;
}

.pt-8 {
    padding-top: 8px;
}

.pb-10 {
    padding-bottom: 10px;
}

.pb-5 {
    padding-bottom: 5px;
}

.pb-15 {
    padding-bottom: 15px !important;
}

.pb-20 {
    padding-bottom: 20px;
}

.mb--10 {
    margin-bottom: -10px;
}

.mb--3 {
    margin-bottom: -3px;
}

.mt-0 {
    margin-top: 0px;
}

.mt-3 {
    margin-top: 3px;
}

.mt-2 {
    margin-top: 2px;
}

.mt-5 {
    margin-top: 5px;
}

.mt-4 {
    margin-top: 4px;
}

.mt-6 {
    margin-top: 6px;
}

.mt-8 {
    margin-top: 8px;
}

.mt-10 {
    margin-top: 10px;
}

.mt-13 {
    margin-top: 13px;
}

.mt-15 {
    margin-top: 15px;
}

.mt-19 {
    margin-top: 19px;
}

.mt-20 {
    margin-top: 20px;
}

.mt-25 {
    margin-top: 25px;
}

.mt-30 {
    margin-top: 30px;
}

.mt--10 {
    margin-top: -10px;
}
.mt--15 {
    margin-top: -15px;
}
.mt--20 {
    margin-top: -20px;
}

.mt--3 {
    margin-top: -3px;
}
.mt--5 {
    margin-top: -5px;
}
.mt--8 {
    margin-top: -8px;
}
.ml--5 {
    margin-left: -5px;
}

.ml--10 {
    margin-left: -10px;
}

.ml-50 {
    margin-left: 50px;
}

.ml-50 {
    margin-left: 50px;
}

.ml-30 {
    margin-left: 30px;
}

.ml-10 {
    margin-left: 10px;
}
.ml-15 {
    margin-left: 15px;
}
.ml-20 {
    margin-left: 20px;
}

.ml-5 {
    margin-left: 5px;
}

.ml-1 {
    margin-left: 1px;
}

.ml-2 {
    margin-left: 2px;
}

.ml-3 {
    margin-left: 3px;
}

.ml-4 {
    margin-left: 4px;
}

.mr-5 {
    margin-right: 5px;
}

.mr-4 {
    margin-right: 4px;
}

.mr-3 {
    margin-right: 3px;
}
.mr-2 {
    margin-right: 2px;
}
.mr-10 {
    margin-right: 10px;
}

.mr-8 {
    margin-right: 8px;
}

.mr-65 {
    margin-right: 65px;
}

.pl-0 {
    padding-left: 0px;
}
.pl-3 {
    padding-left: 3px;
}

.pl-5 {
    padding-left: 5px;
}

.pl-10 {
    padding-left: 10px;
}

.pl-20 {
    padding-left: 20px;
}

.p0 {
    padding: 0px;
}

.m0 {
    margin: 0px;
}

.p1 {
    padding: 1px;
}
.p10 {
    padding: 10px;
}
.p20 {
    padding: 20px;
}
.p-2 {
    padding-right: 2px;
    padding-left: 2px;
}

.pr-2 {
    padding-right: 2px;
}
.pr-3 {
    padding-right: 3px;
}
.p2 {
    padding: 2px 6px 2px 6px;
}

.p3 {
    padding: 1px 6px 1px 6px;
}

.p5 {
    padding: 5px;
}

.pl-15 {
    padding: 1px 5px 1px 15px;
}

.pr-15 {
    padding: 1px 15px 1px 5px;
}

.redColor {
    color: #a94442 !important;
}

.inputb {
    border-left: 0px;
    border-top: 0px;
    border-right: 0px;
    border-bottom: 1px solid #bbb
}

.inputbw {
    border-left: 0px;
    border-top: 0px;
    border-right: 0px;
    border-bottom: 1px solid #bbb;
    background: #FFFFFF;
}

.inputc {
    border-left: 0px;
    border-top: 0px;
    border-right: 0px;
    border-bottom: 0px;
}

.inputw {
    background-color: #fff;
    border: 0px;
    box-shadow: none;
}

.inputwhite {
    border-left: 0px;
    border-top: 0px;
    border-right: 0px;
    border-bottom: 0px solid #bbb
}

.inputgray {
    font-size: 13px;
    border-left: 0px;
    border-top: 0px;
    border-right: 0px;
    border-bottom: 0px solid #bbb;
    background-color: #f9f9f9;
}

.text-right {
    text-align: right !important;
}

.vertical-middle {
    vertical-align: middle !important;
}

.fs-15 {
    font-size: 15px;
}

.large-font {
    font-size: 35px;
    font-weight: 600;
    padding-left: 5px;
    color: #337ab7;
}

.gridStyle {
    width: 100%;
    margin-top: 10px;
}

.btn-grid {
    padding: 5px 9px;
    font-size: 12px;
    line-height: 1.0;
    border-radius: 1px;
}

.btn-wf {
    border-radius: 3px !important;
    padding: 5px 8px;
}

/*.input-group{min-width: 230px;}
*/
.e-pointer {
    cursor: pointer;
    background-color: #EBEBE4;
}

.type-ahead .dropdown-menu {
    min-width: 380px;
}

.table-responsive {
    min-height: 380px;
}

.min-height {
    min-height: 680px;
}

.padding-td {
    padding: 0px;
}

/*
 @Name: layer's style
 @Author: 贤心
 @Blog： sentsin.com
 */

.layui-layer-imgbar,
.layui-layer-imgtit a,
.layui-layer-tab .layui-layer-title span,
.layui-layer-title {
    text-overflow: ellipsis;
    white-space: nowrap
}

*html {
    background-image: url(about:blank);
    background-attachment: fixed
}

html #layui_layer_skinlayercss {
    display: none;
    position: absolute;
    width: 1989px
}

.layui-layer,
.layui-layer-shade {
    position: fixed;
    _position: absolute;
    pointer-events: auto
}

.layui-layer-shade {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    _height: expression(document.body.offsetHeight+"px")
}

.layui-layer {
    -webkit-overflow-scrolling: touch;
    top: 150px;
    left: 0;
    margin: 0;
    padding: 0;
    background-color: #fff;
    -webkit-background-clip: content;
    box-shadow: 1px 1px 50px rgba(0, 0, 0, .3);
    border-radius: 2px;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-duration: .3s;
    animation-duration: .3s
}

.layui-layer-close {
    position: absolute
}

.layui-layer-content {
    position: relative
}

.layui-layer-border {
    border: 1px solid #B2B2B2;
    border: 1px solid rgba(0, 0, 0, .3);
    box-shadow: 1px 1px 5px rgba(0, 0, 0, .2)
}

.layui-layer-moves {
    position: absolute;
    border: 3px solid #666;
    border: 3px solid rgba(0, 0, 0, .5);
    cursor: move;
    background-color: #fff;
    background-color: rgba(255, 255, 255, .3);
    filter: alpha(opacity=50)
}

.layui-layer-load {
    background: url(../images/loading-0.gif) center center no-repeat #fff
}

.layui-layer-ico {
    background: url(../images/icon.png) no-repeat
}

.layui-layer-btn a,
.layui-layer-dialog .layui-layer-ico,
.layui-layer-setwin a {
    display: inline-block;
    *display: inline;
    *zoom: 1;
    vertical-align: top
}

@-webkit-keyframes bounceIn {
    0% {
        opacity: 0;
        -webkit-transform: scale(.5);
        transform: scale(.5)
    }

    100% {
        opacity: 1;
        -webkit-transform: scale(1);
        transform: scale(1)
    }
}

@keyframes bounceIn {
    0% {
        opacity: 0;
        -webkit-transform: scale(.5);
        -ms-transform: scale(.5);
        transform: scale(.5)
    }

    100% {
        opacity: 1;
        -webkit-transform: scale(1);
        -ms-transform: scale(1);
        transform: scale(1)
    }
}

.layer-anim {
    -webkit-animation-name: bounceIn;
    animation-name: bounceIn
}

@-webkit-keyframes bounceOut {
    100% {
        opacity: 0;
        -webkit-transform: scale(.7);
        transform: scale(.7)
    }

    30% {
        -webkit-transform: scale(1.03);
        transform: scale(1.03)
    }

    0% {
        -webkit-transform: scale(1);
        transform: scale(1)
    }
}

@keyframes bounceOut {
    100% {
        opacity: 0;
        -webkit-transform: scale(.7);
        -ms-transform: scale(.7);
        transform: scale(.7)
    }

    30% {
        -webkit-transform: scale(1.03);
        -ms-transform: scale(1.03);
        transform: scale(1.03)
    }

    0% {
        -webkit-transform: scale(1);
        -ms-transform: scale(1);
        transform: scale(1)
    }
}

.layer-anim-close {
    -webkit-animation-name: bounceOut;
    animation-name: bounceOut;
    -webkit-animation-duration: .2s;
    animation-duration: .2s
}

@-webkit-keyframes zoomInDown {
    0% {
        opacity: 0;
        -webkit-transform: scale(.1) translateY(-2000px);
        transform: scale(.1) translateY(-2000px);
        -webkit-animation-timing-function: ease-in-out;
        animation-timing-function: ease-in-out
    }

    60% {
        opacity: 1;
        -webkit-transform: scale(.475) translateY(60px);
        transform: scale(.475) translateY(60px);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out
    }
}

@keyframes zoomInDown {
    0% {
        opacity: 0;
        -webkit-transform: scale(.1) translateY(-2000px);
        -ms-transform: scale(.1) translateY(-2000px);
        transform: scale(.1) translateY(-2000px);
        -webkit-animation-timing-function: ease-in-out;
        animation-timing-function: ease-in-out
    }

    60% {
        opacity: 1;
        -webkit-transform: scale(.475) translateY(60px);
        -ms-transform: scale(.475) translateY(60px);
        transform: scale(.475) translateY(60px);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out
    }
}

.layer-anim-01 {
    -webkit-animation-name: zoomInDown;
    animation-name: zoomInDown
}

@-webkit-keyframes fadeInUpBig {
    0% {
        opacity: 0;
        -webkit-transform: translateY(2000px);
        transform: translateY(2000px)
    }

    100% {
        opacity: 1;
        -webkit-transform: translateY(0);
        transform: translateY(0)
    }
}

@keyframes fadeInUpBig {
    0% {
        opacity: 0;
        -webkit-transform: translateY(2000px);
        -ms-transform: translateY(2000px);
        transform: translateY(2000px)
    }

    100% {
        opacity: 1;
        -webkit-transform: translateY(0);
        -ms-transform: translateY(0);
        transform: translateY(0)
    }
}

.layer-anim-02 {
    -webkit-animation-name: fadeInUpBig;
    animation-name: fadeInUpBig
}

@-webkit-keyframes zoomInLeft {
    0% {
        opacity: 0;
        -webkit-transform: scale(.1) translateX(-2000px);
        transform: scale(.1) translateX(-2000px);
        -webkit-animation-timing-function: ease-in-out;
        animation-timing-function: ease-in-out
    }

    60% {
        opacity: 1;
        -webkit-transform: scale(.475) translateX(48px);
        transform: scale(.475) translateX(48px);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out
    }
}

@keyframes zoomInLeft {
    0% {
        opacity: 0;
        -webkit-transform: scale(.1) translateX(-2000px);
        -ms-transform: scale(.1) translateX(-2000px);
        transform: scale(.1) translateX(-2000px);
        -webkit-animation-timing-function: ease-in-out;
        animation-timing-function: ease-in-out
    }

    60% {
        opacity: 1;
        -webkit-transform: scale(.475) translateX(48px);
        -ms-transform: scale(.475) translateX(48px);
        transform: scale(.475) translateX(48px);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out
    }
}

.layer-anim-03 {
    -webkit-animation-name: zoomInLeft;
    animation-name: zoomInLeft
}

@-webkit-keyframes rollIn {
    0% {
        opacity: 0;
        -webkit-transform: translateX(-100%) rotate(-120deg);
        transform: translateX(-100%) rotate(-120deg)
    }

    100% {
        opacity: 1;
        -webkit-transform: translateX(0) rotate(0);
        transform: translateX(0) rotate(0)
    }
}

@keyframes rollIn {
    0% {
        opacity: 0;
        -webkit-transform: translateX(-100%) rotate(-120deg);
        -ms-transform: translateX(-100%) rotate(-120deg);
        transform: translateX(-100%) rotate(-120deg)
    }

    100% {
        opacity: 1;
        -webkit-transform: translateX(0) rotate(0);
        -ms-transform: translateX(0) rotate(0);
        transform: translateX(0) rotate(0)
    }
}

.layer-anim-04 {
    -webkit-animation-name: rollIn;
    animation-name: rollIn
}

@keyframes fadeIn {
    0% {
        opacity: 0
    }

    100% {
        opacity: 1
    }
}

.layer-anim-05 {
    -webkit-animation-name: fadeIn;
    animation-name: fadeIn
}

@-webkit-keyframes shake {

    0%,
    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0)
    }

    10%,
    30%,
    50%,
    70%,
    90% {
        -webkit-transform: translateX(-10px);
        transform: translateX(-10px)
    }

    20%,
    40%,
    60%,
    80% {
        -webkit-transform: translateX(10px);
        transform: translateX(10px)
    }
}

@keyframes shake {

    0%,
    100% {
        -webkit-transform: translateX(0);
        -ms-transform: translateX(0);
        transform: translateX(0)
    }

    10%,
    30%,
    50%,
    70%,
    90% {
        -webkit-transform: translateX(-10px);
        -ms-transform: translateX(-10px);
        transform: translateX(-10px)
    }

    20%,
    40%,
    60%,
    80% {
        -webkit-transform: translateX(10px);
        -ms-transform: translateX(10px);
        transform: translateX(10px)
    }
}

.layer-anim-06 {
    -webkit-animation-name: shake;
    animation-name: shake
}

@-webkit-keyframes fadeIn {
    0% {
        opacity: 0
    }

    100% {
        opacity: 1
    }
}

.layui-layer-title {
    padding: 0 80px 0 20px;
    height: 42px;
    line-height: 42px;
    border-bottom: 1px solid #eee;
    font-size: 14px;
    color: #333;
    overflow: hidden;
    background-color: #F8F8F8;
    border-radius: 2px 2px 0 0
}

.layui-layer-setwin {
    position: absolute;
    right: 15px;
    *right: 0;
    top: 15px;
    font-size: 0;
    line-height: initial
}

.layui-layer-setwin a {
    position: relative;
    width: 16px;
    height: 16px;
    margin-left: 10px;
    font-size: 12px;
    _overflow: hidden
}

.layui-layer-setwin .layui-layer-min cite {
    position: absolute;
    width: 14px;
    height: 2px;
    left: 0;
    top: 50%;
    margin-top: -1px;
    background-color: #2E2D3C;
    cursor: pointer;
    _overflow: hidden
}

.layui-layer-setwin .layui-layer-min:hover cite {
    background-color: #2D93CA
}

.layui-layer-setwin .layui-layer-max {
    background-position: -32px -40px
}

.layui-layer-setwin .layui-layer-max:hover {
    background-position: -16px -40px
}

.layui-layer-setwin .layui-layer-maxmin {
    background-position: -65px -40px
}

.layui-layer-setwin .layui-layer-maxmin:hover {
    background-position: -49px -40px
}

.layui-layer-setwin .layui-layer-close1 {
    background-position: 0 -40px;
    cursor: pointer
}

.layui-layer-setwin .layui-layer-close1:hover {
    opacity: .7
}

.layui-layer-setwin .layui-layer-close2 {
    position: absolute;
    right: -28px;
    top: -28px;
    width: 30px;
    height: 30px;
    margin-left: 0;
    background-position: -149px -31px;
    *right: -18px;
    _display: none
}

.layui-layer-setwin .layui-layer-close2:hover {
    background-position: -180px -31px
}

.layui-layer-btn {
    text-align: right;
    padding: 0 10px 12px;
    pointer-events: auto
}

.layui-layer-btn a {
    height: 28px;
    line-height: 28px;
    margin: 0 6px;
    padding: 0 15px;
    border: 1px solid #dedede;
    background-color: #f1f1f1;
    color: #333;
    border-radius: 2px;
    font-weight: 400;
    cursor: pointer;
    text-decoration: none
}

.layui-layer-btn a:hover {
    opacity: .9;
    text-decoration: none
}

.layui-layer-btn a:active {
    opacity: .7
}

.layui-layer-btn .layui-layer-btn0 {
    border-color: #4898d5;
    background-color: #2e8ded;
    color: #fff
}

.layui-layer-dialog {
    min-width: 260px
}

.layui-layer-dialog .layui-layer-content {
    position: relative;
    padding: 20px;
    line-height: 24px;
    word-break: break-all;
    overflow: hidden;
    font-size: 14px;
    overflow-x: hidden;
    overflow-y: auto
}

.layui-layer-dialog .layui-layer-content .layui-layer-ico {
    position: absolute;
    top: 16px;
    left: 15px;
    _left: -40px;
    width: 30px;
    height: 30px
}

.layui-layer-ico1 {
    background-position: -30px 0
}

.layui-layer-ico2 {
    background-position: -60px 0
}

.layui-layer-ico3 {
    background-position: -90px 0
}

.layui-layer-ico4 {
    background-position: -120px 0
}

.layui-layer-ico5 {
    background-position: -150px 0
}

.layui-layer-ico6 {
    background-position: -180px 0
}

.layui-layer-rim {
    border: 6px solid #8D8D8D;
    border: 6px solid rgba(0, 0, 0, .3);
    border-radius: 5px;
    box-shadow: none
}

.layui-layer-msg {
    min-width: 180px;
    border: 1px solid #D3D4D3;
    box-shadow: none
}

.layui-layer-hui {
    min-width: 100px;
    background-color: #000;
    filter: alpha(opacity=60);
    background-color: rgba(0, 0, 0, .6);
    color: #fff;
    border: none
}

.layui-layer-hui .layui-layer-content {
    padding: 12px 25px;
    text-align: center
}

.layui-layer-dialog .layui-layer-padding {
    padding: 20px 20px 20px 55px;
    text-align: left
}

.layui-layer-page .layui-layer-content {
    position: relative;
    overflow: auto
}

.layui-layer-iframe .layui-layer-btn,
.layui-layer-page .layui-layer-btn {
    padding-top: 10px
}

.layui-layer-nobg {
    background: 0 0
}

.layui-layer-iframe .layui-layer-content {
    overflow: hidden
}

.layui-layer-iframe iframe {
    display: block;
    width: 100%
}

.layui-layer-loading {
    border-radius: 100%;
    background: 0 0;
    box-shadow: none;
    border: none
}

.layui-layer-loading .layui-layer-content {
    width: 60px;
    height: 24px;
    background: url(../images/loading-0.gif) no-repeat
}

.layui-layer-loading .layui-layer-loading1 {
    width: 37px;
    height: 37px;
    background: url(../images/loading-1.gif) no-repeat
}

.layui-layer-ico16,
.layui-layer-loading .layui-layer-loading2 {
    width: 32px;
    height: 32px;
    background: url(../images/loading-2.gif) no-repeat
}

.layui-layer-tips {
    background: 0 0;
    box-shadow: none;
    border: none
}

.layui-layer-tips .layui-layer-content {
    position: relative;
    line-height: 22px;
    min-width: 12px;
    padding: 5px 10px;
    font-size: 12px;
    _float: left;
    border-radius: 3px;
    box-shadow: 1px 1px 3px rgba(0, 0, 0, .3);
    background-color: #F90;
    color: #fff
}

.layui-layer-tips .layui-layer-close {
    right: -2px;
    top: -1px
}

.layui-layer-tips i.layui-layer-TipsG {
    position: absolute;
    width: 0;
    height: 0;
    border-width: 8px;
    border-color: transparent;
    border-style: dashed;
    *overflow: hidden
}

.layui-layer-tips i.layui-layer-TipsB,
.layui-layer-tips i.layui-layer-TipsT {
    left: 5px;
    border-right-style: solid;
    border-right-color: #F90
}

.layui-layer-tips i.layui-layer-TipsT {
    bottom: -8px
}

.layui-layer-tips i.layui-layer-TipsB {
    top: -8px
}

.layui-layer-tips i.layui-layer-TipsL,
.layui-layer-tips i.layui-layer-TipsR {
    top: 1px;
    border-bottom-style: solid;
    border-bottom-color: #F90
}

.layui-layer-tips i.layui-layer-TipsR {
    left: -8px
}

.layui-layer-tips i.layui-layer-TipsL {
    right: -8px
}

.layui-layer-lan[type=dialog] {
    min-width: 280px
}

.layui-layer-lan .layui-layer-title {
    background: #4476A7;
    color: #fff;
    border: none
}

.layui-layer-lan .layui-layer-btn {
    padding: 10px;
    text-align: right;
    border-top: 1px solid #E9E7E7
}

.layui-layer-lan .layui-layer-btn a {
    background: #BBB5B5;
    border: none
}

.layui-layer-lan .layui-layer-btn .layui-layer-btn1 {
    background: #C9C5C5
}

.layui-layer-molv .layui-layer-title {
    background: #009f95;
    color: #fff;
    border: none
}

.layui-layer-molv .layui-layer-btn a {
    background: #009f95
}

.layui-layer-molv .layui-layer-btn .layui-layer-btn1 {
    background: #92B8B1
}

.layui-layer-iconext {
    background: url(../images/icon-ext.png) no-repeat
}

.layui-layer-prompt .layui-layer-input {
    display: block;
    width: 220px;
    height: 30px;
    margin: 0 auto;
    line-height: 30px;
    padding: 0 5px;
    border: 1px solid #ccc;
    box-shadow: 1px 1px 5px rgba(0, 0, 0, .1) inset;
    color: #333
}

.layui-layer-prompt textarea.layui-layer-input {
    width: 300px;
    height: 100px;
    line-height: 20px
}

.layui-layer-tab {
    box-shadow: 1px 1px 50px rgba(0, 0, 0, .4)
}

.layui-layer-tab .layui-layer-title {
    padding-left: 0;
    border-bottom: 1px solid #ccc;
    background-color: #eee;
    overflow: visible
}

.layui-layer-tab .layui-layer-title span {
    position: relative;
    float: left;
    min-width: 80px;
    max-width: 260px;
    padding: 0 20px;
    text-align: center;
    cursor: default;
    overflow: hidden
}

.layui-layer-tab .layui-layer-title span.layui-layer-tabnow {
    height: 43px;
    border-left: 1px solid #ccc;
    border-right: 1px solid #ccc;
    background-color: #fff;
    z-index: 10
}

.layui-layer-tab .layui-layer-title span:first-child {
    border-left: none
}

.layui-layer-tabmain {
    line-height: 24px;
    clear: both
}

.layui-layer-tabmain .layui-layer-tabli {
    display: none
}

.layui-layer-tabmain .layui-layer-tabli.xubox_tab_layer {
    display: block
}

.xubox_tabclose {
    position: absolute;
    right: 10px;
    top: 5px;
    cursor: pointer
}

.layui-layer-photos {
    -webkit-animation-duration: 1s;
    animation-duration: 1s
}

.layui-layer-photos .layui-layer-content {
    overflow: hidden;
    text-align: center
}

.layui-layer-photos .layui-layer-phimg img {
    position: relative;
    width: 100%;
    display: inline-block;
    *display: inline;
    *zoom: 1;
    vertical-align: top
}

.layui-layer-imgbar,
.layui-layer-imguide {
    display: none
}

.layui-layer-imgnext,
.layui-layer-imgprev {
    position: absolute;
    top: 50%;
    width: 27px;
    _width: 44px;
    height: 44px;
    margin-top: -22px;
    outline: 0;
    blr: expression(this.onFocus=this.blur())
}

.layui-layer-imgprev {
    left: 10px;
    background-position: -5px -5px;
    _background-position: -70px -5px
}

.layui-layer-imgprev:hover {
    background-position: -33px -5px;
    _background-position: -120px -5px
}

.layui-layer-imgnext {
    right: 10px;
    _right: 8px;
    background-position: -5px -50px;
    _background-position: -70px -50px
}

.layui-layer-imgnext:hover {
    background-position: -33px -50px;
    _background-position: -120px -50px
}

.layui-layer-imgbar {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 32px;
    line-height: 32px;
    background-color: rgba(0, 0, 0, .8);
    background-color: #000\9;
    filter: Alpha(opacity=80);
    color: #fff;
    overflow: hidden;
    font-size: 0
}

.layui-layer-imgtit * {
    display: inline-block;
    *display: inline;
    *zoom: 1;
    vertical-align: top;
    font-size: 12px
}

.layui-layer-imgtit a {
    max-width: 65%;
    overflow: hidden;
    color: #fff
}

.layui-layer-imgtit a:hover {
    color: #fff;
    text-decoration: underline
}

.layui-layer-imgtit em {
    padding-left: 10px;
    font-style: normal
}

.readonly {
    background-color: #eee;
    opacity: 1;
}

/* ==========================================================================
make sure browsers use the pointer cursor for anchors, even with no href
========================================================================== */
a:hover {
    cursor: pointer;
}

.hand {
    cursor: pointer;
}

/* ==========================================================================
Metrics and Health styles
========================================================================== */
#threadDump .popover,
#healthCheck .popover {
    top: inherit;
    display: block;
    font-size: 10px;
    max-width: 1024px;
}

#healthCheck .popover {
    margin-left: -50px;
}

.health-details {
    min-width: 400px;
}

/* ==========================================================================
start Password strength bar style
========================================================================== */
ul#strengthBar {
    display: inline;
    list-style: none;
    margin: 0;
    margin-left: 15px;
    padding: 0;
    vertical-align: 2px;
}

.point:last {
    margin: 0 !important;
}

.point {
    background: #DDD;
    border-radius: 2px;
    display: inline-block;
    height: 5px;
    margin-right: 1px;
    width: 20px;
}

/* ==========================================================================
Custom alerts for notification
========================================================================== */
.alerts .alert {
    text-overflow: ellipsis;
}

.alert pre {
    background: none;
    border: none;
    font: inherit;
    color: inherit;
    padding: 0;
    margin: 0;
}

.alert .popover pre {
    font-size: 10px;
}

.alerts .toast {
    position: fixed;
    width: 100%;
}

.alerts .toast.left {
    left: 5px;
}

.alerts .toast.right {
    right: 5px;
}

.alerts .toast.top {
    top: 55px;
}

.alerts .toast.bottom {
    bottom: 55px;
}

@media screen and (min-width: 480px) {
    .alerts .toast {
        width: 50%;
    }
}

/* ==========================================================================
entity tables helpers
========================================================================== */
/* Remove Bootstrap padding from the element
   http://stackoverflow.com/questions/19562903/remove-padding-from-columns-in-bootstrap-3 */
.no-padding-left {
    padding-left: 0 !important;
}

.no-padding-right {
    padding-right: 0 !important;
}

.no-padding-top {
    padding-top: 0 !important;
}

.no-padding-bottom {
    padding-bottom: 0 !important;
}

.no-padding {
    padding: 0 !important;
}

/* bootstrap 3 input-group 100% width
   http://stackoverflow.com/questions/23436430/bootstrap-3-input-group-100-width */
.width-min {
    width: 1% !important;
}

/* Makes toolbar not wrap on smaller screens
 http://www.sketchingwithcss.com/samplechapter/cheatsheet.html#right */
.flex-btn-group-container {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    flex-direction: row;
    -webkit-justify-content: flex-end;
    justify-content: flex-end;
}

.jh-table>tbody>tr>td {
    /* Align text in td verifically (top aligned by Bootstrap) */
    vertical-align: middle;
}

.jh-table>thead>tr>th>.glyphicon-sort,
.jh-table>thead>tr>th>.glyphicon-sort-by-attributes,
.jh-table>thead>tr>th>.glyphicon-sort-by-attributes-alt {
    /* less visible sorting icons */
    opacity: 0.5;
}

.jh-table>thead>tr>th>.glyphicon-sort:hover,
.jh-table>thead>tr>th>.glyphicon-sort-by-attributes:hover,
.jh-table>thead>tr>th>.glyphicon-sort-by-attributes-alt:hover {
    /* full visible sorting icons and pointer when mouse is over them */
    opacity: 1;
    cursor: pointer;
}

/* ==========================================================================
entity detail page css
========================================================================== */
.dl-horizontal.jh-entity-details>dd {
    margin-bottom: 15px;
}

@media screen and (min-width: 768px) {
    .dl-horizontal.jh-entity-details>dt {
        margin-bottom: 15px;
    }

    .dl-horizontal.jh-entity-details>dd {
        border-bottom: 1px solid #eee;
        padding-left: 180px;
        margin-left: 0;
    }
}

/* ==========================================================================
ui bootstrap tweaks
========================================================================== */
.nav,
.pagination,
.carousel,
.panel-title a {
    cursor: pointer;
}

.datetime-picker-dropdown>li.date-picker-menu div>table .btn-default,
.uib-datepicker-popup>li>div.uib-datepicker>table .btn-default {
    border: 0;
}

.datetime-picker-dropdown>li.date-picker-menu div>table:focus,
.uib-datepicker-popup>li>div.uib-datepicker>table:focus {
    outline: none;
}



/* jhipster-needle-css-add-main JHipster will add new css style */


img.nav-logo {
    width: 53px;
    height: 40px;
    position: absolute;
    top: 5px;
    left: 16px;
    border: 0;
}

.p30 {
    padding: 10px;
}

.pop-input {
    padding: 10px 20px;
}

.bgGray {
    background-color: #EBEBE4
}

.bgWarning {
    background-color: #fcf8e3
}

.bgDanger {
    background-color: #f2dede
}

.bgInfo {
    background-color: #d9edf7
}

.bgwhite {
    background-color: #DDD
}

.grid-primary {
    background-color: #f4f3f3 !important;
    font-weight: 600;
    color: #337ab7;
}

.fw-600 {
    font-weight: 600;
}

.fw-500 {
    font-weight: 500;
}

.webimlist {
    width: 220px;
    height: 340px;
    /*overflow-y: hidden ; overflow-x:hidden; */
    overflow: hidden;
}

.webimlist ul {
    display: block;
    min-width: 220px;
    min-height: 260px;
}

.webimlist ul li {
    color: #000;
    margin-bottom: 10px;
    height: 60px;
    line-height: 30px;
    cursor: pointer;
    width: 210px;
    overflow: hidden;
    padding: 5px;
    margin: 0px auto;
}

.webimlist ul li.onli {
    background: #555;
    border-radius: 5px;
}

.webimlist ul li:hover {
    background: #777;
    border-radius: 5px;
}

.webimlist ul li div {
    float: left;
    height: 50px;
    line-height: 50px;
    margin-left: 5px;
    position: relative;
}

.webimlist ul li div img {
    width: 50px;
}

.newmsg {
    width: 20px;
    height: 20px;
    text-align: center;
    border-radius: 10px;
    background: #ffc000;
    color: #000;
    display: block;
    overflow: hidden;
    line-height: 20px;
    position: absolute;
    margin: -19px 1px 1px -28px;
    font-size: 10px;
    text-align: center;
}

.webimlist .uinfo {}

.webimlist .uinfo dl dd,
.webimlist .uinfo dl dt {
    height: 16px;
    line-height: 16px;
    text-overflow: ellipsis;
    color: #aaaaaa;
    width: 135px;
    overflow: hidden;
}

.webimlist .uinfo dl dt {
    color: #ffffff;
    font-weight: normal;
}

.webimlist .uinfo dl dt .fensi {
    color: #aaaaaa;
    font-size: 9px;
    margin-left: 10px;
}

.webimlist .relist {
    margin-top: 10px;
}

.webimlist .relist input {
    background: #555555;
    width: 155px;
    margin-left: 5px;
    padding-left: 5px;
    color: #d0cfcf;
    height: 30px;
    line-height: 30px;
    border-radius: 5px;
}

.webimlist .scroll_track {
    filter: alpha(Opacity=80);
    -moz-opacity: 0.5;
    opacity: 0.5;
}

.webimlist .scroll_vertical_bar {
    width: 3px;
    overflow: hidden;
}

.webimleft {
    margin-left: 10px;
    float: left;
    border-radius: 5px;
    width: 240px;
    height: 500px;
    background: #F6F6F6;
    overflow: hidden;
}

.webimleft>ul {
    margin-right: 5px;
}

.wkit-powered-by {
    display: none;
}

.webimsear {
    width: 220px;
    margin: 10px auto;
}

#J_WkitToast {
    display: none;
}

#webimco {
    margin-left: 5px;
}

.nav-pills>li.active>a,
.nav-pills>li.active>a:hover,
.nav-pills>li.active>a:focus {
    color: #555555;
    font-weight: 600;
    background-color: #E7E7E7;
}

.list-group-item.active>.badge,
.nav-pills>.active>a>.badge {
    color: #555555;
}

/*.layui-layer-setwin .layui-layer-max{ display: none; }
.layui-layer-setwin .layui-layer-maxmin{ display: block; float: left  }*/

.recentCont {
    list-style-type: none;
}

.inactive>a {
    font-weight: 600;
    color: #555555;
}

#verCode {
    text-decoration: none;
    font-weight: bold;
}

.rides-cs {
    font-size: 12px;
    background: #777777;
    position: fixed;
    top: 75%;
    right: 0px;
    _position: absolute;
    z-index: 1500;
    border-radius: 6px 0px 0 6px;
}

.rides-cs a {
    color: #00A0E9;
}

.rides-cs a:hover {
    color: #ff8100;
    text-decoration: none;
}

.rides-cs .floatL {
    width: 36px;
    float: left;
    position: relative;
    z-index: 1;
    margin-top: 6px;
    height: 181px;
}

.rides-cs .floatL a {
    font-size: 0;
    text-indent: -999em;
    display: block;
}

.rides-cs .floatR {
    width: 130px;
    float: left;
    padding: 5px;
    overflow: hidden;
}

.rides-cs .floatR .cn {
    background: #F7F7F7;
    border-radius: 6px;
    margin-top: 14px;
}

.rides-cs .cn .titZx {
    font-size: 14px;
    color: #333;
    font-weight: 600;
    line-height: 24px;
    padding: 5px;
    text-align: center;
}

.rides-cs .cn ul {
    padding: 0px;
}

.rides-cs .cn ul li {
    line-height: 38px;
    height: 38px;
    border-bottom: solid 1px #E6E4E4;
    overflow: hidden;
    text-align: center;
}

.rides-cs .cn ul li span {
    color: #777;
}

.rides-cs .cn ul li a {
    color: #777;
}

.rides-cs .cn ul li img {
    vertical-align: middle;
}

.rides-cs .btnOpen,
.rides-cs .btnCtn {
    position: relative;
    z-index: 9;
    top: 25px;
    left: 0;
    background-image: url("../images/shopnc.png");
    background-repeat: no-repeat;
    display: block;
    height: 146px;
    padding: 8px;
}

.rides-cs .btnOpen {
    background-position: -410px 0;
}

.rides-cs .btnCtn {
    background-position: -450px 0;
}

.rides-cs ul li.top {
    border-bottom: solid #ACE5F9 1px;
}

.well>div:first-child {
    padding: 19px 19px 0px 19px;
}

.well-body {
    margin-top: 45px;
    margin-bottom: 0px;
    padding-bottom: 0px;
}


.rides-cs ul li.bot {
    border-bottom: none;
}

.div-news {
    width: 100%;
    white-space: normal;
}

.pre-news {
    font-size: 15px;
    white-space: pre-wrap;
    white-space: -moz-pre-wrap;
    white-space: -pre-wrap;
    white-space: -o-pre-wrap;
    word-wrap: break-word;
}

.pre-text {
    margin-top: 3px;
    padding: 5px;
    white-space: normal;
    word-break: break-all;
    font-size: 12px;
}

.grid-text {
    display: inline-block;
    padding: 5px;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    white-space: nowrap;
    -ms-text-overflow: ellipsis;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    overflow: hidden;
    height: 100%;
}

.grid-input {
    height: 100% !important;
    border-bottom: 1px solid #d4d4d4 !important;
    color: #505050 !important;
    border-top: none !important;
    border-right: none !important;
    border-left: none !important;
    padding: 2px !important;
}

.table-input {
    background-clip: padding-box;
    font-size: 12px;
    /*    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;*/
    background-color: #fdfdfd;
    box-sizing: border-box;
    padding: 0px 5px;
    margin: 0;
    width: 100%;
    height: 25px;
    border: 1px solid #bfbfbf;
    line-height: 1.5;
    border-radius: 0px;
}

.search-row {
    padding-top: 10px;
    padding-bottom: 10px;
    border: 1px solid #e5e5e5;
    background-color: #eeeeee;
}

.table-input-lg {
    background-clip: padding-box;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    box-sizing: border-box;
    padding: 0px 5px;
    margin: 0;
    width: 100%;
    height: 28px;
    font-size: 12px;
    border: 1px solid #bfbfbf;
    line-height: 1.5;
    border-radius: 3px;
}

.table-addon {
    font-size: 12px;
    border: 1px solid #bfbfbf;
    border-bottom-left-radius: 1px;
    border-top-left-radius: 1px;
    border-bottom-right-radius: 0px;
    border-top-right-radius: 0px;
    padding: 3px 5px;
    background-color: #eeeeee;
}

.table-btn {
    padding: 3px 7px;
    font-size: 12px;
    line-height: 1.5;
    height: 25px;
    border: 1px solid #bfbfbf;
    margin-left: -1px;
    border-radius: 0px;
}

.table-btn-lg {
    padding: 3px 7px;
    font-size: 13px;
    line-height: 1.5;
    height: 28px;
    border: 1px solid #bfbfbf;
    margin-left: -1px;
    border-radius: 0px;
}

.table-addon-lg {
    font-size: 13px;
    border: 1px solid #bfbfbf;
    border-top-left-radius: 1px;
    border-bottom-left-radius: 1px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    padding: 4px 6px;
    background-color: #eeeeee;
}



.td-60 {
    width: 60px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.td-80 {
    width: 80px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.td-100 {
    width: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.td-120 {
    width: 120px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.td-150 {
    width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.td-200 {
    width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.td-250 {
    width: 250px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.td-350 {
    width: 350px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.td-450 {
    width: 450px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.td-500 {
    width: 500px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.mw-100 {
    max-width: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.mw-200 {
    max-width: 160px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.mw-280 {
    max-width: 280px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.mw-300 {
    max-width: 300px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.mw-360 {
    max-width: 360px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.mw-400 {
    max-width: 400px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.mw-500 {
    max-width: 500px;
    overflow: hidden;
    text-overflow: ellipsis;
}
.mw-800 {
    max-width: 800px;
    overflow: hidden;
    text-overflow: ellipsis;
}
.mw-1360 {
    max-width: 1360px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.mh-800 {
    max-height: 800px;
    overflow: auto;
}

.minw-120 {
    min-width: 120px;
}

.r-auto {
    right: auto !important;
    left: 0 !important;
}

.l-auto {
    left: auto !important;
    right: 0 !important;
}

.role-pills>ul>li>a {
    border-radius: 0px;
    padding: 6px 6px;
    margin-bottom: 0;
    font-size: 13px;
    font-weight: 500;
    line-height: 1.42857143;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    border: 1px solid #C1C1C1;
    margin-left: -1px;
    color: #333;
    border-radius: 1px;
}

.customs-pills>ul>li>a {
    border-radius: 0px;
    padding: 5px 6px;
    margin-bottom: 0;
    font-size: 12px;
    font-weight: 500;
    line-height: 1.42857143;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    border: 1px solid #C1C1C1;
    margin-left: -1px;
    color: #333;
    background-color: #f5f5f5;
}

.nav-pills>li.active>a,
.nav-pills>li.active>a:hover,
.nav-pills>li.active>a:focus {
    color: #333;
    background-color: #F5F5F5;
    border-bottom: 0px;
}

.ops-input {
    max-width: 100%;
    display: block;
    width: 100%;
    height: 25px;
    padding: 0px 4px;
    font-size: 12px;
    line-height: 1.5;
    color: #555555;
    border: 1px solid #bfbfbf;
    border-radius: 2px;
    background-color: #fdfdfd;
    background-image: none;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
    -webkit-transition: border-color ease-in-out .15s, -webkit-box-shadow ease-in-out .15s;
    -o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
}

.voucher-input {
    background-color: #e8f6fd;
    font-weight: 600;
    text-align: center;
    font-size: 17px;
    color: #555555;
}

.voucher-total {
    font-weight: 600;
    font-size: 17px;
    color: #555555;
}

.ops-btn {
    padding: 3px 7px;
    height: 25px;
}

.title-btn {
    padding: 0px 10px;
}

.ops-addon {
    font-size: 10px;
    padding: 3px;
    border-radius: 0px;
    background-color: #eeeeee;
    border: 1px solid #bfbfbf;
    border-left: none;
    border-right: none;
}

.ops-label {
    display: inline-block;
    max-width: 100%;
    margin-bottom: 0px;
    font-size: 12px;
    font-weight: 600;
    color: #505050;
}

.ops-lg-label {
    display: inline-block;
    max-width: 100%;
    margin-bottom: 0px;
    font-size: 13px;
    font-weight: 600;
    color: #505050;
}

.ops-input[disabled],
.ops-input[readonly],
fieldset[disabled] .ops-input {
    background-color: #eee;
    opacity: 1;
}

.calendar-btn {
    padding: 3px 7px;
    font-size: 12px;
    line-height: 1.5;
    height: 25px;
    border: 1px solid #bfbfbf;
    margin-left: -1px;
    border-radius: 2px;
}

.tab-content>.active {
    background-color: white;
}

.form-group {
    margin-bottom: 0px;
}

.f-12 {
    font-size: 12px;
}

.f-13rem {
    font-size: 1.3rem;
}

.f-13 {
    font-size: 13px;
}

.f-14 {
    font-size: 14px;
}

.f-15 {
    font-size: 15px;
}

.f-16 {
    font-size: 16px;
}

.f-17 {
    font-size: 17px;
}

.f-18 {
    font-size: 18px;
}

.f-19 {
    font-size: 19px;
}

/*.badge {
    background-color: #d9534f !important;
}*/
.ops-title {
    background: #cecece;
    text-align: center;
    font-size: 13px;
    height: 15px;
    padding: 5px !important;
    border: 1px solid #a2a2a2 !important;
}

.b-top {
    border-top: 1px solid #d4d4d4;
}

.bt-0 {
    border-top: none;
}

.b-left {
    border-left: 1px solid #d4d4d4;
}

.b-right {
    border-right: 1px solid #d4d4d4;
}

.b-bottom {
    border-bottom: 1px solid #d4d4d4;
}

.b-all {
    border: 1px solid #d4d4d4;
}

.bb-0 {
    border-bottom: none;
}

.ops-divider {
    margin-top: 15px;
    margin-bottom: 15px;
    border: 0;
    border-top: 1px solid #dddddd;
}

.ops-divider-10 {
    margin-top: 10px;
    margin-bottom: 10px;
    border: 0;
    border-top: 1px solid #dddddd;
}
.ops-divider-sm {
    margin-top: 8px;
    margin-bottom: 5px;
    border: 0;
    border-top: 1px solid #dddddd;
}

.bg-alert {
    background-color: #88e1fb;
}

.bg-gray {
    background-color: #f5f5f5;
}

.bg-grey {
    background-color: #eeeeee !important;
}

/*ul, ol {
    background-color: aqua;
}*/
#interactive.viewport {
    position: relative;
}

#interactive.viewport>canvas,
#interactive.viewport>video {
    max-width: 100%;
    width: 100%;
}

@charset "UTF-8";

.collapsable-source pre {
    font-size: small;
}

.input-field {
    display: flex;
    align-items: center;
    width: 260px;
}

.input-field label {
    flex: 0 0 auto;
    padding-right: 0.5rem;
}

.input-field input {
    flex: 1 1 auto;
    height: 20px;
}

.input-field button {
    flex: 0 0 auto;
    height: 28px;
    font-size: 20px;
    width: 40px;
}

.icon-barcode {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz48IURPQ1RZUEUgc3ZnIFBVQkxJQyAiLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4iICJodHRwOi8vd3d3LnczLm9yZy9HcmFwaGljcy9TVkcvMS4xL0RURC9zdmcxMS5kdGQiPjxzdmcgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB3aWR0aD0iMzIiIGhlaWdodD0iMzIiIHZpZXdCb3g9IjAgMCAzMiAzMiI+PHBhdGggZD0iTTAgNGg0djIwaC00ek02IDRoMnYyMGgtMnpNMTAgNGgydjIwaC0yek0xNiA0aDJ2MjBoLTJ6TTI0IDRoMnYyMGgtMnpNMzAgNGgydjIwaC0yek0yMCA0aDF2MjBoLTF6TTE0IDRoMXYyMGgtMXpNMjcgNGgxdjIwaC0xek0wIDI2aDJ2MmgtMnpNNiAyNmgydjJoLTJ6TTEwIDI2aDJ2MmgtMnpNMjAgMjZoMnYyaC0yek0zMCAyNmgydjJoLTJ6TTI0IDI2aDR2MmgtNHpNMTQgMjZoNHYyaC00eiI+PC9wYXRoPjwvc3ZnPg==);
}

.overlay {
    overflow: hidden;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.3);
}

.overlay__content {
    top: 50%;
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    max-height: 90%;
    max-width: 800px;
}

.overlay__close {
    position: absolute;
    right: 0;
    padding: 0.5rem;
    width: 2rem;
    height: 2rem;
    line-height: 2rem;
    text-align: center;
    background-color: white;
    cursor: pointer;
    border: 3px solid black;
    font-size: 1.5rem;
    margin: -1rem;
    border-radius: 2rem;
    z-index: 100;
    box-sizing: content-box;
}

.overlay__content video {
    width: 100%;
    height: 100%;
}

.overlay__content canvas {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
}

#interactive.viewport {
    position: relative;
}

#interactive.viewport>canvas,
#interactive.viewport>video {
    max-width: 100%;
    width: 100%;
}

canvas.drawing,
canvas.drawingBuffer {
    position: absolute;
    left: 0;
    top: 0;
}

/* line 16, ../sass/_viewport.scss */
.controls fieldset {
    border: none;
}

/* line 19, ../sass/_viewport.scss */
.controls .input-group {
    float: left;
}

/* line 21, ../sass/_viewport.scss */
.controls .input-group input,
.controls .input-group button {
    display: block;
}

/* line 25, ../sass/_viewport.scss */
.controls .reader-config-group {
    float: right;
}

/* line 28, ../sass/_viewport.scss */
.controls .reader-config-group label {
    display: block;
}

/* line 30, ../sass/_viewport.scss */
.controls .reader-config-group label span {
    width: 11rem;
    display: inline-block;
    text-align: right;
}

/* line 37, ../sass/_viewport.scss */
.controls:after {
    content: '';
    display: block;
    clear: both;
}

/* line 22, ../sass/_viewport.scss */
#result_strip {
    margin: 10px 0;
    border-top: 1px solid #EEE;
    border-bottom: 1px solid #EEE;
    padding: 10px 0;
}

/* line 28, ../sass/_viewport.scss */
#result_strip ul.thumbnails {
    padding: 0;
    margin: 0;
    list-style-type: none;
    width: auto;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
}

/* line 37, ../sass/_viewport.scss */
#result_strip ul.thumbnails>li {
    display: inline-block;
    vertical-align: middle;
    width: 160px;
}

/* line 41, ../sass/_viewport.scss */
#result_strip ul.thumbnails>li .thumbnail {
    padding: 5px;
    margin: 4px;
    border: 1px dashed #CCC;
}

/* line 46, ../sass/_viewport.scss */
#result_strip ul.thumbnails>li .thumbnail img {
    max-width: 140px;
}

/* line 49, ../sass/_viewport.scss */
#result_strip ul.thumbnails>li .thumbnail .caption {
    white-space: normal;
}

/* line 51, ../sass/_viewport.scss */
#result_strip ul.thumbnails>li .thumbnail .caption h4 {
    text-align: center;
    word-wrap: break-word;
    height: 40px;
    margin: 0px;
}

/* line 61, ../sass/_viewport.scss */
#result_strip ul.thumbnails:after {
    content: "";
    display: table;
    clear: both;
}

@media (max-width: 603px) {

    /* line 2, ../sass/phone/_core.scss */
    #container {
        margin: 10px auto;
        -moz-box-shadow: none;
        -webkit-box-shadow: none;
        box-shadow: none;
    }
}

@media (max-width: 603px) {

    /* line 5, ../sass/phone/_viewport.scss */
    #interactive.viewport {
        width: 100%;
        height: auto;
        overflow: hidden;
    }

    /* line 20, ../sass/phone/_viewport.scss */
    #result_strip {
        margin-top: 5px;
        padding-top: 5px;
    }

    #result_strip ul.thumbnails {
        width: 100%;
        height: auto;
    }

    /* line 24, ../sass/phone/_viewport.scss */
    #result_strip ul.thumbnails>li {
        width: 150px;
    }

    /* line 27, ../sass/phone/_viewport.scss */
    #result_strip ul.thumbnails>li .thumbnail .imgWrapper {
        width: 130px;
        height: 130px;
        overflow: hidden;
    }

    /* line 31, ../sass/phone/_viewport.scss */
    #result_strip ul.thumbnails>li .thumbnail .imgWrapper img {
        margin-top: -25px;
        width: 130px;
        height: 180px;
    }
}

/*-------------------------------------
zTree Style

version:    3.5.19
author:     Hunter.z
email:      hunter.z@263.net
website:    http://code.google.com/p/jquerytree/

-------------------------------------*/

.ztree * {
    padding: 0;
    margin: 0;
    font-size: 12px;
    font-family: Verdana, Arial, Helvetica, AppleGothic, sans-serif
}

.ztree {
    margin: 0;
    padding: 5px;
    color: #333
}

.ztree li {
    padding: 0;
    margin: 0;
    list-style: none;
    line-height: 14px;
    text-align: left;
    white-space: nowrap;
    outline: 0
}

.ztree li ul {
    margin: 0;
    padding: 0 0 0 18px
}

.ztree li ul.line {
    background: url(../images/line_conn.gif) 0 0 repeat-y;
}

.ztree li a {
    padding: 1px 3px 0 0;
    margin: 0;
    cursor: pointer;
    height: 17px;
    color: #333;
    background-color: transparent;
    text-decoration: none;
    vertical-align: top;
    display: inline-block
}

.ztree li a:hover {
    text-decoration: underline
}

.ztree li a.curSelectedNode {
    padding-top: 0px;
    background-color: #FFE6B0;
    color: black;
    height: 16px;
    border: 1px #FFB951 solid;
    opacity: 0.8;
}

.ztree li a.curSelectedNode_Edit {
    padding-top: 0px;
    background-color: #FFE6B0;
    color: black;
    height: 16px;
    border: 1px #FFB951 solid;
    opacity: 0.8;
}

.ztree li a.tmpTargetNode_inner {
    padding-top: 0px;
    background-color: #316AC5;
    color: white;
    height: 16px;
    border: 1px #316AC5 solid;
    opacity: 0.8;
    filter: alpha(opacity=80)
}

.ztree li a.tmpTargetNode_prev {}

.ztree li a.tmpTargetNode_next {}

.ztree li a input.rename {
    height: 14px;
    width: 80px;
    padding: 0;
    margin: 0;
    font-size: 12px;
    border: 1px #7EC4CC solid;
    *border: 0px
}

.ztree li span {
    line-height: 16px;
    margin-right: 2px
}

.ztree li span.button {
    line-height: 0;
    margin: 0;
    width: 16px;
    height: 16px;
    display: inline-block;
    vertical-align: middle;
    border: 0 none;
    cursor: pointer;
    outline: none;
    background-color: transparent;
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-image: url("../images/zTreeStandard.png");
    *background-image: url("../images/zTreeStandard.gif")
}

.ztree li span.button.chk {
    width: 13px;
    height: 13px;
    margin: 0 3px 0 0;
    cursor: auto
}

.ztree li span.button.chk.checkbox_false_full {
    background-position: 0 0
}

.ztree li span.button.chk.checkbox_false_full_focus {
    background-position: 0 -14px
}

.ztree li span.button.chk.checkbox_false_part {
    background-position: 0 -28px
}

.ztree li span.button.chk.checkbox_false_part_focus {
    background-position: 0 -42px
}

.ztree li span.button.chk.checkbox_false_disable {
    background-position: 0 -56px
}

.ztree li span.button.chk.checkbox_true_full {
    background-position: -14px 0
}

.ztree li span.button.chk.checkbox_true_full_focus {
    background-position: -14px -14px
}

.ztree li span.button.chk.checkbox_true_part {
    background-position: -14px -28px
}

.ztree li span.button.chk.checkbox_true_part_focus {
    background-position: -14px -42px
}

.ztree li span.button.chk.checkbox_true_disable {
    background-position: -14px -56px
}

.ztree li span.button.chk.radio_false_full {
    background-position: -28px 0
}

.ztree li span.button.chk.radio_false_full_focus {
    background-position: -28px -14px
}

.ztree li span.button.chk.radio_false_part {
    background-position: -28px -28px
}

.ztree li span.button.chk.radio_false_part_focus {
    background-position: -28px -42px
}

.ztree li span.button.chk.radio_false_disable {
    background-position: -28px -56px
}

.ztree li span.button.chk.radio_true_full {
    background-position: -42px 0
}

.ztree li span.button.chk.radio_true_full_focus {
    background-position: -42px -14px
}

.ztree li span.button.chk.radio_true_part {
    background-position: -42px -28px
}

.ztree li span.button.chk.radio_true_part_focus {
    background-position: -42px -42px
}

.ztree li span.button.chk.radio_true_disable {
    background-position: -42px -56px
}

.ztree li span.button.switch {
    width: 18px;
    height: 18px
}

.ztree li span.button.root_open {
    background-position: -92px -54px
}

.ztree li span.button.root_close {
    background-position: -74px -54px
}

.ztree li span.button.roots_open {
    background-position: -92px 0
}

.ztree li span.button.roots_close {
    background-position: -74px 0
}

.ztree li span.button.center_open {
    background-position: -92px -18px
}

.ztree li span.button.center_close {
    background-position: -74px -18px
}

.ztree li span.button.bottom_open {
    background-position: -92px -36px
}

.ztree li span.button.bottom_close {
    background-position: -74px -36px
}

.ztree li span.button.noline_open {
    background-position: -92px -72px
}

.ztree li span.button.noline_close {
    background-position: -74px -72px
}

.ztree li span.button.root_docu {
    background: none;
}

.ztree li span.button.roots_docu {
    background-position: -56px 0
}

.ztree li span.button.center_docu {
    background-position: -56px -18px
}

.ztree li span.button.bottom_docu {
    background-position: -56px -36px
}

.ztree li span.button.noline_docu {
    background: none;
}

.ztree li span.button.ico_open {
    margin-right: 2px;
    background-position: -110px -16px;
    vertical-align: top;
    *vertical-align: middle
}

.ztree li span.button.ico_close {
    margin-right: 2px;
    background-position: -110px 0;
    vertical-align: top;
    *vertical-align: middle
}

.ztree li span.button.ico_docu {
    margin-right: 2px;
    background-position: -110px -32px;
    vertical-align: top;
    *vertical-align: middle
}

.ztree li span.button.edit {
    margin-right: 2px;
    background-position: -110px -48px;
    vertical-align: top;
    *vertical-align: middle
}

.ztree li span.button.remove {
    margin-right: 2px;
    background-position: -110px -64px;
    vertical-align: top;
    *vertical-align: middle
}

.ztree li span.button.ico_loading {
    margin-right: 2px;
    background: url(../images/loading.gif) no-repeat scroll 0 0 transparent;
    vertical-align: top;
    *vertical-align: middle
}

ul.tmpTargetzTree {
    background-color: #FFE6B0;
    opacity: 0.8;
    filter: alpha(opacity=80)
}

span.tmpzTreeMove_arrow {
    width: 16px;
    height: 16px;
    display: inline-block;
    padding: 0;
    margin: 2px 0 0 1px;
    border: 0 none;
    position: absolute;
    background-color: transparent;
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: -110px -80px;
    background-image: url("../images/zTreeStandard.png");
    *background-image: url("../images/zTreeStandard.gif")
}

ul.ztree.zTreeDragUL {
    margin: 0;
    padding: 0;
    position: absolute;
    width: auto;
    height: auto;
    overflow: hidden;
    background-color: #cfcfcf;
    border: 1px #00B83F dotted;
    opacity: 0.8;
    filter: alpha(opacity=80)
}

.zTreeMask {
    z-index: 10000;
    background-color: #cfcfcf;
    opacity: 0.0;
    filter: alpha(opacity=0);
    position: absolute
}


.tracking .panel-group {
    margin-bottom: 5px;
}

.video-js .vjs-tech {
    position: relative;
}

.dropdown-submenu {
    position: relative;
}

.dropdown-submenu>.dropdown-menu {
    top: 0;
    left: 100%;
    width: fit-content;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    margin-top: -6px;
    margin-left: -1px;
    -webkit-border-radius: 0 6px 6px 6px;
    -moz-border-radius: 0 6px 6px;
    border-radius: 0 6px 6px 6px;
}

.dropdown-submenu:hover>.dropdown-menu {
    display: block;
}

.dropdown-submenu>a:after {
    display: block;
    content: " ";
    float: right;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid;
    border-width: 5px 0 5px 5px;
    border-left-color: #666;
    margin-top: 5px;
    margin-right: -10px;
}

.dropdown-submenu:hover>a:after {
    border-left-color: #ccc;
}

.dropdown-submenu.pull-left {
    float: none;
}

.dropdown-submenu.pull-left>.dropdown-menu {
    left: -100%;
    margin-left: 10px;
    -webkit-border-radius: 6px 0 6px 6px;
    -moz-border-radius: 6px 0 6px 6px;
    border-radius: 6px 0 6px 6px;
}

.ops-label .checkbox-inline input {
    margin-top: 1px;
    margin-left: -17px !important;
}

.ops-label .radio-inline input {
    margin-top: 1px;
    margin-left: -17px !important;
}

.row-title .checkbox-inline input {
    margin-top: 1px;
    margin-left: -17px !important;
}

td .checkbox-inline input {
    margin-top: 1px;
    margin-left: -17px !important;
}

.step {
    padding-left: 10px;
}

.bulkShipping {
    overflow: hidden;
}

.bulkShipping .step li {
    float: left;
    min-width: 120px;
    margin-right: 5px;
    color: #ccc;
    transition: all .2s linear;
    -webkit-transition: all .2s linear;
}

.bulkShipping .step li strong {
    transform-origin: left bottom;
    -webkit-transform-origin: left bottom;
    font-weight: 400;
    font-size: 36px;
    display: inline-block;
    position: relative;
    top: 13px;
}

.bulkShipping .step li.active {
    color: #d9534f;
}

.bulkShipping ul li {
    list-style: none;
}

.bulkShipping .step li.active i {
    background: #d9534f;
}

.bulkShipping .step li i {
    display: inline-block;
    width: 100%;
    height: 3px;
    background: #ccc;
    transform: skewX(45deg);
    -webkit-transform: scaleY(45deg);
}

.orderInfo dl dt {
    font-size: 19px;
    margin-top: 15px;
    color: #3379b7;
}

.orderInfo dl dd {
    padding-left: 5px;
    font-size: 16px;
    color: #989898;
}

.ops-dl dt {
    width: 100px;
}

.ops-dl dd {
    margin-left: 110px;
    max-width: 300px;
    overflow: hidden;
    text-overflow: ellipsis;
}

input.pw,
textarea.pw {
    -webkit-text-security: disc;
}


.pre-media-container {
    background: #f7f9fa;
    border: 1px solid #e5e5e5;
    border-radius: 4Px;
    padding: 12px 10px;
    position: relative;
    width: auto;
}

.pre-media-container-reply {
    border: 1px solid #ff6a00;
    padding: 12px 10px;
    border-radius: 4px;
    position: relative;
    width: auto;
}

.pre-media-body {
    word-break: break-all;
    color: #373d41;
    font-size: 13px;
    line-height: 16px;
    white-space: pre-wrap;
}

.ops-title-15 {
    color: #5a5a5a;
    font-size: 15px;
}

.ops-title-14 {
    color: #5a5a5a;
    font-size: 14px;
}

.jh-table>thead>tr {
    background-color: #f5f5f5;
    position: sticky;
    top: -2px;
    padding-top: 10px;
    z-index: 999;
}

.jh-table>tbody>tr>td {
    vertical-align: top;
}

.innerTable {
    padding: 0px !important;
    height: 100%;
}

.innerTable>table {
    margin-bottom: 0px;
    background-color: rgba(225, 225, 225, 0.00) !important;
    height: 100%;
    border-collapse: collapse !important;
}

.innerTable>table>tbody>tr:nth-child(1)>td {
    border-top: 0px !important;
}

.innerTable>table>tbody>tr>td {
    border-top: 1px solid #dddddd !important;
}

.row-strip-gray {
    background-color: #f5f5f5;
}

.row-strip-white {
    background-color: #f9f9f9;
}


.categoryAll>ul {
    display: flex;
    flex-wrap: nowrap;
    /*justify-content: center;*/
    padding: 0;
    margin-bottom: 8px;
}

.categoryAll>ul>li {
    flex-basis: 15%;
    list-style: none;
    text-align: center;
    padding: 6px 8px;
    outline: none;
    border-radius: 3px;
}

.categoryAll>ul>li>a {
    color: #333;
    font-size: 13px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-decoration: none;
}

.categoryAll>ul>li>a:hover {
    color: #337ab7;
    text-decoration: none;
}

.Active {
    background-color: #00A0E9;
}

.Active>a {
    color: #fff !important;
}

.labelSpan {
    white-space: nowrap;
    vertical-align: middle;
    font-size: 13px;
}

.dialogRight {
    padding-top: 8px;
}

.dialogRight>.input-group {
    display: flex;
    justify-content: left;
    flex-wrap: nowrap;
    margin-bottom: 8px;
}

.dialogRight>.input-group>p {
    flex-basis: 25%;
    text-align: right;
    padding-right: 10px;
}

.dialogRight>.input-group>div {
    flex-basis: 66%;
}

.menu-3cn {
    min-width: 695px !important;
}

.menu-3en {
    min-width: 780px !important;
}

.menu-2cn {
    min-width: 475px !important;
}

.menu-2en {
    min-width: 650px !important;
}

.menu-1 {
    min-width: 210px !important;
}

.open>.menuList>div>li>a {
    padding: 7px 20px 7px 20px;
    min-width: 230px;
    text-decoration: none;
}

.open>.menuList>div>li>a>span:first-child {
    padding-right: 4px;
}

.open>.dropdown-menu>li {
    /* border-bottom: 1px solid #e5e5e5; */
    margin-right: 10px;
}

.open>.dropdown-menu>li:last-child {
    border-bottom: none;
}

.open>.menuList {
    background-color: #fdfdfd;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    right: auto;
}

.text-muted-a {
    color: #777777 !important;
}

.open .inner-submenu a {
    color: #777777;
    cursor: initial;
}

.open .inner-submenu a:hover {
    background-color: transparent !important;
    color: #777777;
}

.open .inner-menu:hover,
.open>.menuList>div li a:hover {
    background-color: #f5f5f5;
}

.open .menuList .dropdown-menu>li>a {
    padding-top: 7px;
    padding-bottom: 7px;
}

.open .inner-menu>a {
    display: block;
    padding: 7px 30px 7px 42px !important;
    font-weight: normal;
    line-height: 1.42857143;
    color: #333333;
    white-space: nowrap;
    text-decoration: none;
}

.dropdown-menu>li>a {
    padding: 3px 25px 3px 20px;
}

.dropdown-menu>li>a>span:first-child {
    padding-right: 4px;
}

.open .inner-menu>a>span:first-child::before {
    content: '';
    display: inline-block;
    width: 6px;
    height: 6px;
    background: #333;
    border-radius: 50%;
    margin-right: 8px;
    margin-bottom: 2px;
}

.open .active a {
    color: #ffffff !important;
    text-decoration: none;
    outline: 0;
    background-color: #5bc0de !important;
}

.dropdown-menu>div>li>a {
    display: block;
    padding: 3px 20px;
    clear: both;
    font-weight: normal;
    line-height: 1.42857143;
    color: #333333;
    white-space: nowrap;
}

.TableP {
    color: #ddd;
    margin: 0;
    padding-right: 5px;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(0, -50%);
}

.TableContent {
    word-wrap: break-word;
    text-align: left;
    margin: 0;
    margin-left: 5px;
    margin-right: 5px;
    background-color: #fff;
    word-break: break-all;
    font-size: 13px;
}

.CurInput {
    display: inline-block;
    height: 100%;
    border: 0px;
    border-bottom: 1px solid #bbb;
    background-color: #fff;
    font-size: 14px;
    font-weight: 600;
    box-shadow: none;
    border-radius: 0px;
    padding: 4px 3px;
}

.quoteInput {
    display: inline-block;
    height: 100%;
    border: 0px;
    border-bottom: 1px solid #bbb;
    background-color: #fff;
    font-size: 14px;
    box-shadow: none;
    border-radius: 0px;
    padding: 4px 3px;
}

.AssistInput {
    display: inline-block;
    height: 100%;
    background-color: #fff;
    font-size: 13px;
    box-shadow: none;
    border-radius: 0px;
    padding: 6px 3px;
}

.CurInput:focus,
.AssistInput:focus {
    box-shadow: none;
}

.CurInput[readonly] {
    background-color: #fff;
    opacity: 1;
}

.inputw:focus {
    border: 0px;
    box-shadow: none;
}

.AddRemove {
    border: none !important;
    background-color: #fff !important;
    padding: 4px;
    position: relative;
}

.AddRemove>div {
    position: absolute;
    left: 0%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.AddRemove>div>p {
    color: #ddd;
    /*display: block;*/
    /*border: 1px solid #bbb;*/
    /*background-color: #fff;*/
    /*border-radius: 50%;*/
    /*padding: 2px 5px;*/
    /*transform: scale(0.6);*/
    /*outline: none !important;*/
}

.AddRemove>div>button>span {
    color: #bbb;
}

.AddRemoveHead {
    width: 1%;
    visibility: hidden;
    border-top: none;
}

.TdDiv {
    position: absolute;
    top: 100%;
    background-color: #f3f3f3;
    border: 1px solid #bbb;
    border-top: none;
    padding: 20px 20px;
    box-sizing: border-box;
    outline: none;
    z-index: 100;

}

.border-light {
    border-color: rgb(0, 255, 255) !important;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 3px rgba(0, 225, 225, 0.6) !important;
}

.TdDiv>div {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}

.TdDiv>div:last-child {
    margin-bottom: 0px;
}

.TdDiv>div>input {
    background-color: #fff;
    flex-basis: 80%;
    border: 1px solid #cecece;
}

.TdDiv>div>p {
    flex-basis: 20%;
    text-align: right;
    padding-right: 6px;
}

.voucher-tabel {
    border-left: none;
    border-right: 2px solid #bbb;
    margin-bottom: 5px;
}

.voucher-bd-right {
    border-right: 2px solid #bbb !important;
}

.voucher-bd-top {
    border-top: 2px solid #bbb !important;
}

.voucher-bd-left {
    border-left: 2px solid #bbb !important;
}

.voucher-bd-bottom {
    border-bottom: 2px solid #bbb !important;
}

.voucher-tabel>thead>tr>th,
.voucher-tabel>tbody>tr>th,
.voucher-tabel>tfoot>tr>th,
.voucher-tabel>thead>tr>td,
.voucher-tabel>tbody>tr>td,
.voucher-tabel>tfoot>tr>td {
    border: 1px solid #bbb;
}

/* rd10 */
.tdDivHr {
    margin: 15px 0;
    border-color: #e6e6e6;
}

.minh-20 {
    min-height: 20px;
}

.w-350 {
    width: 350px;
}

.bg-white {
    background-color: white;
}

.body-container {
    padding: 2px;
}

.accountTitlesTreeDiv {
    width: 280px;
    min-width: 280px;
    min-height: 280px;
    position: -webkit-sticky;
    /* Safari */
    position: sticky;
    top: 0;
    /* box-sizing: border-box; */
    padding: 5px 10px;
    border: 1px solid #e5e5e5;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 23px;
}

.accountTitlesTreeDiv .btn {
    padding-left: 0px;
}

.accountTitlesTreeDiv .btn:focus {
    outline: none;
}

.OpacityOne {
    animation: OpacityOne 1s;
    animation-fill-mode: forwards;
    overflow: hidden;
}

.DisplayinlineBlock {
    display: inline-block;
}

.DisplayBlock {
    display: block;
}

.DisplayNone {
    display: none;
}

.OpacityZreo {
    animation: OpacityZreo 0.5s;
    animation-fill-mode: forwards;
    overflow: hidden;
}

.TreeHide {
    animation: closeTree 0.7s;
    animation-fill-mode: forwards;
}

.TreeShow {
    animation: openTree 0.7s;
    animation-fill-mode: forwards;
}

/*#lineChartDiv,
#barLineAeraChartDiv,
#NoticeNewsDiv,
#HeardTitleDiv,
#infomationDiv {
    padding: 0px 15px;
}*/

.infomationCard {
    background-color: #fff;
    text-align: center !important;
    padding: 0.8rem !important;
}

.infomationCard-heard {
    display: flex;
    justify-content: flex-end;
}

.infomationCard-heard>span {
    display: inline-flex !important;
    align-items: center !important;
}

.infomationCard-body {
    line-height: 1.3333333;
    margin: 0 !important;
    font-size: 30px;
    font-weight: 600;
}

.infomationCard-footer {
    margin-bottom: 1rem !important;
    margin-top: 4px;
}

.Card {
    height: calc(27rem + 15px);
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    box-shadow: rgb(35 46 60 / 4%) 0 2px 4px 0;
    border: 1px solid rgba(101, 109, 119, .16);
    border-radius: 4px;
}

.cardLeft {
    height: calc(40rem + 15px);
}

.cardFile {
    height: calc(40rem + 15px);
}

.Card-header {
    color: inherit;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: 0 0;
    border-bottom: 1px solid #f5f5f5;
}

.Card-title {
    display: block;
    margin: 15px 10px;
    font-size: 13px;
    font-weight: 500;
    line-height: 1.5rem;
}

.Card-title:focus,
.viewNotice:focus {
    outline: none;
}

.Card-body {
    overflow-y: auto;
    padding: 1rem 1.5rem;
    font-size: 12px;
    /*min-height: 215px;*/
}

.Card-body>:not(template):not(:last-child) {
    padding-bottom: 0.6rem !important;
}

.Card-body>:not(template):not(:first-child) {
    padding-top: 1rem !important;
}

.Card-body>:not(template)~:not(template) {
    border-top: 1px solid rgba(101, 109, 119, .16) !important;
}

.NoticeAddDelete {
    display: flex;
    justify-content: flex-end;
    color: #656d77 !important;
    /*    font-size: 1.3rem;*/
}

.NoticeAddDelete div {
    margin-right: 5px;
}

.NoticeAddDelete div:focus {
    outline: none;
}

.text-nowrap {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.content-date {
    display: flex;
    margin-top: 3px;
    color: #656d77 !important;
    /*font-size: 13px;*/
}

.f-25 {
    font-size: 25px;
}

.totalShipments {
    line-height: 1.1;
}

.totalShipmentCol8 {
    padding-right: 8px;
    font-size: 2.5rem;
}

.setOutSideStyle {
    border-radius: 5px !important;
    box-shadow: rgb(35 46 60 / 4%) 0 2px 4px 0;
    border: 1px solid rgba(101, 109, 119, .16);
    padding: 0.35rem 0.75rem 0.75rem 0.75rem;
}

.lineAreaStyle {
    padding: 0.75rem 0 0 0;
}

.PanelDefault>.panel-heading {
    background-color: #fff;
    border: none;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    padding-bottom: 0px;
}

.dashBoardTitle {
    font-size: 1.1rem;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: .04em;
    line-height: 1.3;
    color: #656d77;
}

.CardTitle {
    padding: 8px 0px 5px 0px;
    display: block;
    margin: 0 0 1rem;
    font-size: 15px;
    font-weight: 600;
    line-height: 1.5rem;
}

.DashboardActive {
    display: block !important;
}

.DashboardIsShow {
    display: none;
}

.dropDownStyle {
    border: none;
    background-color: #fff;
    color: #656d77;
    padding: 0;
    font-size: 12px;
    margin-top: -7px;
}

.dropDownStyle::after {
    content: "";
    display: inline-block;
    vertical-align: 0.306em;
    width: 0.36em;
    height: 0.36em;
    border-bottom: 1px solid;
    border-left: 1px solid;
    margin-right: 0.1em;
    margin-left: 0.4em;
    transform: rotate(-45deg);
}

.dropDownStyle:hover {
    background-color: #fff;
    color: #656d77;
}

.dropDownStyle:focus {
    outline: none !important;
    background-color: #fff !important;
    color: #656d77 !important;
}

.BodyContainer {
    padding: 0px;
    padding-bottom: 5px;
}

.lineAreaBodyStyle {
    position: relative;
    padding: 0;
}

.setInnerChartDiv {
    position: absolute;
    top: 3.2rem;
    left: 2.5rem;
    font-size: 13px;
}

.setLineHeight {
    position: relative;
    height: 11vh;
}

#chartjs-tooltip {
    border-radius: 7px;
    box-shadow: 2px 2px 6px -4px #999;
    padding: 0px;

}

.otherCards {
    height: auto;
    padding: 1rem;
}

.otherCardsDiv {
    display: flex;
    align-items: center !important;
}

.otherCardsDiv-auto {
    flex: 0 0 auto;
    width: auto;
    padding: 0px 0.5rem;
}

.otherCardsDiv-col {
    padding: 0px 0.5rem;
}

.text-white {
    color: #fff !important;
}

.text-green {
    color: #2fb344;
}

.text-red {
    color: #d63939 !important;
}

.bg-blue {
    background: #206bc4;
}

.bg-green {
    background: #2fb344;
}

.bg-yellow {
    background: #f59f00;
}

.bg-twitter {
    background: #1da1f2 !important;
}

.bg-facebook {
    background: #3b5998 !important;
}

.padding-lr-30 {
    padding: 0 30px;
}

.avatar {
    width: 4.5rem;
    height: 4.5rem;
    font-size: calc(4.5rem / 2.8571429);
    font-weight: 500;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    text-transform: uppercase;
    vertical-align: bottom;
    border-radius: 4px;
}

.mb-30 {
    margin-bottom: 30px;
}

.fw-500 {
    font-weight: 500;
}

.inline-flex {
    display: inline-flex;
    width: 100%;
}

.inline-flex .spanTitle {
    min-width: 60px;
    font-size: 12px;
    padding-top: 6px;
}

.inline-flex .form-control {
    height: 26px;
}

.InnerContentDIvTwo {
    display: flex;
}

.z-1000 {
    z-index: 1000;
}

.z-1 {
    z-index: 1 !important;
}

.pr-5 {
    padding-right: 5px;
}

.TreeFilterStyle {
    width: 260px;
    position: absolute;
    top: 30px;
    border: 1px solid #e5e5e5;
    box-shadow: rgb(35 46 60 / 4%) 0 2px 4px 0;
    background-color: #fff;
    border-radius: 4px;
    padding: 10px;
    overflow: hidden;
    z-index: 10;
}

.filterDiv {
    margin-bottom: -8px;
    position: relative;
    margin-right: 0px;
}

.filterDiv>p {
    margin-top: 6px;
    margin-bottom: 0px;
    width: 60px;
    height: 40px;
}

.isOenFilter:focus {
    outline: none;
}

.showHeight {
    display: block;
}

.hideHeight {
    display: none;
}

.FilterSearch>li>a {
    margin-right: 5px;
    border: none;
    padding: 6px 10px;
    border-radius: 4px;
}

.FilterSearch>.active>a {
    background-color: #337ab7 !important;
    color: #fff !important;
}

.FilterSearch>li:focus {
    outline: none;
}

.ptb-5 {
    padding: 5px 0;
}

@keyframes OpacityOne {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
        visibility: visible;
        z-index: 100;

    }
}

@keyframes OpacityZreo {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
        visibility: hidden;
        z-index: 0;
    }
}

@keyframes closeTree {
    0% {
        width: 280px;
        min-width: 280px;
    }

    100% {
        width: 40px;
        min-width: 40px;
    }
}

@keyframes openTree {
    0% {
        width: 40px;
        min-width: 40px;
    }

    100% {
        width: 280px;
        min-width: 280px;
    }
}

.PCTable {
    display: table;
}

.PhoneProcess {
    display: none;
}

@media (max-width:991px) {
    .container-fluid {
        padding: 0px;
    }

    .phoneHeardingDiv1 {
        display: inline-block;
    }

    .phoneHeardingDiv2 {
        display: inline;
        float: right;
    }

    .phoneHeardingDiv2 .pkgNum {
        font-size: 18px;
        font-weight: bold;
    }

    .PCTable {
        display: none;
    }

    .PhoneProcess {
        display: block;
        /* max-height: 8rem; */
        overflow: auto;
    }

    .PhoneProcessList {
        position: relative;
        width: 100%;
    }

    .PhoneProcessList .dashed-line {
        position: absolute;
        border-right: 2px dotted #efefef;
        left: 16px;
        top: 0.3rem;
    }

    .PhoneProcessList ul {
        display: flex;
        list-style-type: none;
        margin-top: 0.4rem;
        margin-bottom: 0.5rem;
        padding-left: 10px;
    }

    .PhoneProcessList ul .SubIcon {
        z-index: 100;
    }

    .PhoneProcessList ul .SubContent {
        padding: 0px 10px;

    }

    .PhoneProcessList ul .SubContent p {
        font-size: 15px;
        color: rgb(86, 86, 86);
        margin-bottom: 2px;
    }

    .PhoneProcessList ul .SubContent .SubDate {
        font-size: 14px;
    }
}

@media (max-width: 700px) {
    .phoneH2spanStyle {
        display: block;
        margin-bottom: 5px;
        font-size: 25px;
        font-weight: 600;
    }

    .phoneSmallStyle {
        display: block;
        margin-left: 0px;
        font-size: 16px;
        line-height: 1.5;
    }

    .phoneHeardingDiv1 {
        display: block;
        margin-bottom: 4px;
        font-size: 15px;
    }

    .phoneHeardingDiv2 {
        display: block;
        text-align: left !important;
        float: none;
        line-height: 1.2;
        font-size: 13px;
        color: rgb(229 229 229 / 80%);
    }

    .phoneHeardingDiv2 span {
        margin-left: 0px;
    }
}

@media (max-width: 500px) {
    .phoneHeader {
        margin-top: 10px;
    }

    .phoneHeader h2 {
        margin-top: 10px;
    }

    .PhoneProcessList ul {
        margin-bottom: 0.7rem;
    }

    .phoneSmallStyle {
        font-size: 14px;
    }

    .PhoneProcessList ul .SubContent p {
        font-size: 15px;
    }

    .PhoneProcessList ul .SubContent .SubDate {
        font-size: 14px;
    }
}

@media (max-width: 375px) {
    .phoneSmallStyle {
        font-size: 13px;
    }

    .PhoneProcessList ul .SubContent p {
        font-size: 13px;
    }

    .PhoneProcessList ul .SubContent .SubDate {
        font-size: 12px;
    }
}

.minw-70 {
    min-width: 70px;
}

/* rd10 */

/* 报表模板 */
#designer-host,
#viewer-host {
    margin: 0 auto;
    width: 100%;
    height: 72vh;
    margin-bottom: 15px;
    border: 1px solid #e5e5e5;
    box-shadow: rgb(35 46 60 / 4%) 0 2px 4px 0;
}

.reportModalColor {
    background-color: #205f78;
    color: #fff;
}

.showModal {
    display: block;
}

.hideModal {
    display: none;
}

/* 报表模板 */

/* switch修改 */
.SwitchLabel {
    margin: 0;
}

.SwitchLabel p {
    margin-bottom: 5px;
    margin-left: 2px;
    font-size: 12px;
    font-weight: 400;
    word-break: break-word;
}

.checkedContent {
    animation: checkedColor 0.5s;
    animation-fill-mode: forwards;
}

.unCheckedContent {
    animation: unCheckedColor 0.5s;
    animation-fill-mode: forwards;
}

@keyframes checkedColor {
    0% {
        color: #777777;
    }

    100% {
        color: #269abc;
    }
}

@keyframes unCheckedColor {
    0% {
        color: #269abc;
    }

    100% {
        color: #777777;
    }
}

.switch-button {
    position: absolute;
    width: 0;
    height: 0;
    opacity: 0;
    margin: 0;
}

.switch-button-core {
    display: inline-block;
    position: relative;
    transition: all .3s;
    width: 40px;
    height: 20px;
    border: 1px solid #7b7b7b;
    border-radius: 10px;
    background-color: #7b7b7b;
    margin: 0px;
    outline: none;
    vertical-align: middle;
}

.is-checked+.switch-button-core {
    border-color: #337ab7;
    background-color: #337ab7;
}

.switch-button-core::before {
    content: '';
    display: block;
    height: 16px;
    width: 16px;
    position: absolute;
    border-radius: 100%;
    left: 1px;
    top: 1px;
    background-color: #fff;
    transition: all .3s;
}

.is-checked+.switch-button-core::before {
    left: 100%;
    margin-left: -17px;
    transition: all .2s linear;
}

/* switch修改 */
/* 首页补充样式 */
.messageDiv {
    margin-top: 40px;
    opacity: 0.6;
    font-size: 12px;
}

.tabCard {
    padding: 1.5rem 0rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 1 !important;
    background-color: #fff;
}

.tabCard-content {
    display: flex;
    justify-content: space-around;
    flex-direction: column;
}

.tabCard-content h5 {
    margin-right: 0.5rem;
    margin-top: 0px;
    font-size: 18px;
    font-weight: 600;
    color: #555555;
}

.mb-1 {
    margin-bottom: 1px;
}

@media (max-width: 1700px) {
    .content-date p {
        display: none;
    }
}

@media (max-width: 1550px) {
    .infomationCard-body {
        font-size: 25px;
    }

    .infomationCard-footer {
        font-size: 13px;
    }
}

@media (max-width: 1440px) {

    .infomationCard-body,
    .totalShipmentCol8 {
        font-size: 20px;
    }

    .viewNotice,
    .infomationCard-heard,
    .totalShipmentCol4,
    .infomationCard-footer,
    .setInnerChartDiv {
        font-size: 12px;
    }

    .CardTitle,
    .Card-title {
        font-size: 13px;
    }
}

/* 首页补充样式 */

a>em {
    font-size: 75%;
}

/* 弹窗界面优化 */
.GuideRatesModalBodyDiv {
    padding: 5px;
    margin-top: -15px;
    margin: 0 auto;
    /*    background-color: #f3f3f3;*/
}

.Review {
    width: 100%;
}

.Review .ops-input[readonly],
.Review .ops-input[disabled] {
    color: #777;
    box-shadow: none;
}

.Review .ops-input[readonly]+.ops-addon {
    background-color: #eeeeee;
}

.Review .ops-input[readonly]:focus {
    box-shadow: none;
    border: 1px solid #bfbfbf;
}

.checkbox-inline,
.radio-inline {
    font-size: 1.3rem;
}

.checkbox-inline input,
.radio-inline input {
    margin-top: 2px;
}

.checkbox-inline input[type="checkbox"],
.radio-inline input[type="radio"] {
    margin-left: -17px;
}



.rateDialogTitleContent {
    font-size: 13px;
    color: #777777;
}

.rateTtlContent {
    font-size: 13px;
    margin-right: 8px;
}

.rateTtlTr {
    padding: 5px;
}

.sortHide>.glyphicon-sort {
    opacity: 0;
}

.sortHide:hover>.glyphicon-sort {
    opacity: 0.5;
}

.hoverShow>.glyphicon {
    opacity: 0;
}

.hoverShow:hover>.glyphicon {
    opacity: 1;
}

.hoverShowTag>.hoverShowTag {
    opacity: 0;
}

.hoverShowTag:hover>.hoverShowTag {
    opacity: 1;
}

/* 弹窗界面优化 */
/* 空运弹窗界面优化 */
.mr-0 {
    margin-right: 0;
}

.pt-15 {
    padding-top: 15px;
}

.pl-0 {
    padding-left: 0;
}

.text-left {
    text-align: left;
}

.text-center {
    text-align: center;
}

.chargeDiv {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.chargeDiv>span {
    font-weight: 600;
    font-size: 12px;
}

.chargeDiv div {
    flex-basis: 49%;
    margin-bottom: 4px;
}

.pr-0 {
    padding-right: 0;
}

/* 空运弹窗界面优化 */
/* 官网介绍 */
.backTop {
    position: fixed;
    bottom: 30%;
    right: 14px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 56px;
    height: 56px;
    color: #fff;
    font-size: 12px;
    background-color: #777;
    border-radius: 4px;
    cursor: pointer;
    opacity: .7;
}

.backTop:hover,
.backTop:focus {
    color: #fff;
    text-decoration: none;
}

.dialogWarper {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: auto;
    margin: 0;
    border-radius: 20px;
}

.showDialog {
    display: block;
    animation: dialogIsShow 0.3s;
    animation-fill-mode: forwards;
    z-index: 2011;
    cursor: default;
}

.hideDialog {
    animation: dialogIsHide 0.3s;
    animation-fill-mode: forwards;
    z-index: 0;
}

.removeDialogWarper {
    display: none;
}

.dialogDiiv {
    width: 300px;
    position: relative;
    margin: 0 auto 50px;
    margin-top: 15vh;
    background: #fff;
    border-radius: 12px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, .3);
    box-sizing: border-box;
    cursor: default;
}

.dialogHeader {
    padding: 0;
}

.dialogHeader button {
    position: absolute;
    top: 12px;
    right: 12px;
    padding: 0;
    border: none;
    background: 0 0;
    outline: 0;
    cursor: pointer;
}

.dialogBody {
    padding: 0;
    vertical-align: top;
}

.dialogBody .img {
    border-radius: 30px
}

.backgroundDiv {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: #000;
    z-index: 2000;
    animation: DialogDivOpacityOne 0.3s;
    animation-fill-mode: forwards;
}

.removeDiv {
    animation: DialogDivOpacityZreo 0.3s;
    animation-fill-mode: forwards;
}

.divImg img {
    border-radius: 12px;
}

@keyframes DialogDivOpacityOne {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 0.5;
    }
}

@keyframes DialogDivOpacityZreo {
    0% {
        opacity: 0.5;
    }

    100% {
        opacity: 0;
    }
}

@keyframes dialogIsShow {
    0% {
        transform: translate3d(0, -20px, 0);
        opacity: 0;
    }

    100% {
        transform: translateZ(0);
        opacity: 1;
    }
}

@keyframes dialogIsHide {
    0% {
        transform: translateZ(0);
        opacity: 1;
    }

    100% {
        transform: translate3d(0, -20px, 0);
        opacity: 0;
    }
}

.divBtn {
    display: inline-block;
}

.introductionContent {
    font-size: 18px;
    margin: 0px;
}

.featuretteDivContent h2 {
    margin-bottom: 20px !important;
}

.featuretteDivContent h2 p {
    margin-bottom: 6px;
}

.featuretteDivContent .lead,
.divModal-content li,
.introductionContent,
.panelPrimaryDiv,
.faqDiv li {
    line-height: 1.7;
}

.faqDiv h4 {
    line-height: 1.5;
}

.contentTitleText {
    font-size: 22px;
}

.contentTitleTextPh {
    display: none !important;
}

.contentTitleTextPC,
.featuretteDivContent h2 p {
    display: inline-block;
}

@media (max-width: 991px) {
    .btnGroup {
        margin-top: 40px;
        min-width: 750px;
    }

    .btnGroup button {
        font-size: 12px;
    }

    .divBtn a {
        font-size: 12px;
        padding: 5px 10px;
        margin-top: 5px;
    }

    .introductionContent,
    .panelPrimaryDiv {
        font-size: 16px;
    }

    .introductionContent:first-child {
        margin-top: 16px;
    }

    .panelPrimaryDiv>div {
        font-size: 15px !important;
    }

    .divBtn {
        display: block;
    }

    .divModal img {
        width: 48% !important;
        height: 48% !important;
    }

    .featuretteDivImage img {
        width: 70%;
    }

    .specialImg img {
        width: 45%;
    }

    .featuretteDivContent .lead {
        font-size: 17px;
    }

    .divModal h1,
    .tableContrastDivTitle h1,
    .featuretteDivContent h2 {
        font-size: 24px;
    }

    .tableContrastDivContent {
        font-size: 13px;
    }

    .featuretteDivContent a {
        position: relative;
        left: 50%;
        transform: translate(-50%, 0);
    }

    .contentTitleTextPC {
        display: none !important;
    }

    .contentTitleTextPh {
        display: block !important;
        font-size: 22px;
    }

    .faqDiv h1 {
        font-size: 30px;
    }

    .faqDiv h3 {
        font-size: 20px;
    }

    .faqDiv h4 {
        font-size: 16px;
    }
}

@media (max-width: 500px) {
    .introductionContent {
        font-size: 14px;
    }

    .introductionContent strong {
        font-size: 15px;
    }

    .containerDesgin {
        width: 100%;
        padding: 0;
        margin: 0;
        margin-top: 20px !important;
    }

    .containerDesginTitle {
        font-size: 24px;
    }

    .containerDesginTitle small {
        font-size: 15px;
    }

    .featuresDiv,
    .faqDiv {
        width: 95%;
        padding: 0;
    }

    .containerDesgin address,
    .divModal-content,
    .featuretteDivContent .lead {
        font-size: 14px !important;
    }

    .divModal h1,
    .tableContrastDivTitle h1,
    .featuretteDivContent h2 {
        font-size: 22px;
    }

    .contentTitleTextPh {
        font-size: 20px;
    }

    .tableContrastDivContent {
        font-size: 13px;
    }

    .divModal img {
        width: 45% !important;
        height: 45% !important;
    }

    .faqDiv h1 {
        font-size: 26px;
    }

    .faqDiv h3 {
        font-size: 18px;
    }

    .faqDiv h4 {
        font-size: 15px;
    }

    .faqDiv li {
        font-size: 13px;
    }

    .ulPhone {
        padding-left: 0;
        list-style: none;
    }

    .ulPhone ul {
        padding-left: 30px;
    }
}

.label-btn {
    margin-top: -3px;
    line-height: 1;
}

/* 官网介绍 */
/* 跟踪状态 */
* {
    margin: 0;
    padding: 0;
}

ul {
    list-style: none;
}

.package-info {
    margin: 10px 14px 0;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
}

ul.package-status {
    width: 100%;
    margin-bottom: 0px;
}

ul.package-status li.package-status-list {
    color: #666;
    font-size: 12px;
    border-left: 1px solid #eee;
    padding-left: 25px;
    position: relative;
    padding-bottom: 26px;
}

.color-grey {
    color: #666;
}

.color-orange {
    color: #f71e09;
}

ul.package-status li.package-status-list:last-child {
    border: 0;
    color: #f71e09;
}

ul.package-status li.package-status-list:last-child span {
    background: #f71e09;
}

ul.package-status li .status-title {
    font-weight: 600;
}

ul.package-status li .status-details {
    padding: 4px 0;
    line-height: 22px;
}

ul.package-status li.package-status-list span {
    width: 8px;
    height: 8px;
    display: inline-block;
    position: absolute;
    left: -5px;
    top: 8px;
    background: #eee;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
}

ul.package-status li.package-status-list i {
    width: 24px;
    height: 24px;
    display: inline-block;
    position: absolute;
    left: -12px;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    background-color: #fff !important;
}

.icon-package {
    width: 24px;
    height: 24px;
    display: inline-block;
    margin-bottom: -5px;
}

.icon-package-deliveryGuide {
    background: url(../images/svg/submited.svg) no-repeat center;
    background-size: 80%;
}

.icon-package-vgmSent {
    background: url(../images/svg/matched.svg) no-repeat center;
    background-size: 80%;
}

.icon-package-Cancelled {
    background: url(../images/svg/cancel.svg) no-repeat center;
    background-size: 80%;
}

.icon-package-wmsIn {
    background: url(../images/svg/wmsIn.svg) no-repeat center;
    background-size: 80%;
}

.icon-package-wmsIn-doing {
    background: url(../images/svg/wmsIn-doing.svg) no-repeat center;
    background-size: 80%;
}

.icon-package-atd {
    background: url(../images/svg/atd.svg) no-repeat center;
    background-size: 70%;
}

.icon-package-etd {
    background: url(../images/svg/estimated.svg) no-repeat center;
    background-size: 70%;
}
.icon-package-bookingEtd {
    background: url(../images/svg/estimated.svg) no-repeat center;
    background-size: 70%;
}
.icon-package-eta {
    background: url(../images/svg/estimated.svg) no-repeat center;
    background-size: 70%;
}

.icon-package-transferDate {
    background: url(../images/svg/estimated.svg) no-repeat center;
    background-size: 70%;
}

.icon-package-deliveryDate {
    background: url(../images/svg/estimated.svg) no-repeat center;
    background-size: 70%;
}

.icon-package-CtnStuffed {
    background: url(../images/svg/CtnStuffed.svg) no-repeat center;
    background-size: 70%;
}

.icon-package-CustomReleased {
    background: url(../images/svg/customs.svg) no-repeat center;
    background-size: 70%;
}

.icon-package-Deliveried {
    background: url(../images/svg/Deliveried.svg) no-repeat center;
    background-size: 70%;
}

.icon-package-Booked {
    background: url(../images/svg/Booked.svg) no-repeat center;
    background-size: 70%;
}

.icon-package-SoReleased {
    background: url(../images/svg/SoReleased.svg) no-repeat center;
    background-size: 70%;
}

.icon-package-TruckArranged {
    background: url(../images/svg/TruckArranged.svg) no-repeat center;
    background-size: 80%;
}

.icon-package-pickup {
    background: url(../images/svg/pickup.svg) no-repeat center;
    background-size: 70%;
}

.icon-package-emsPickup {
    background: url(../images/svg/pickup.svg) no-repeat center;
    background-size: 70%;
}

.icon-package-CustomArranged {
    background: url(../images/svg/customs.svg) no-repeat center;
    background-size: 70%;
}

.icon-package-arrivedPod {
    background: url(../images/svg/arrivedPod.svg) no-repeat center;
    background-size: 70%;
}

.icon-package-plConfirmed {
    background: url(../images/svg/plConfirmed.svg) no-repeat center;
    background-size: 70%;
}

.icon-package-wmsOut {
    background: url(../images/svg/wmsOut.svg) no-repeat center;
    background-size: 80%;
}

.icon-package-wmsOut-doing {
    background: url(../images/svg/wmsOut-doing.svg) no-repeat center;
    background-size: 80%;
}

.icon-package-transferOut {
    background: url(../images/svg/transferOut.svg) no-repeat center;
    background-size: 70%;
}

.icon-package-customsInspection {
    background: url(../images/svg/customsInspection.svg) no-repeat center;
    background-size: 70%;
}
.icon-package-customsInspectionExp {
    background: url(../images/svg/customsInspection.svg) no-repeat center;
    background-size: 70%;
}
.icon-package-customCleared {
    background: url(../images/svg/customs.svg) no-repeat center;
    background-size: 70%;
}

.icon-package-ctnAvailable {
    background: url(../images/svg/ctnAvailable.svg) no-repeat center;
    background-size: 70%;
}

.icon-package-deliveryAppointed {
    background: url(../images/svg/deliveryAppointed.svg) no-repeat center;
    background-size: 70%;
}

.icon-package-Delivering {
    background: url(../images/svg/Delivering.svg) no-repeat center;
    background-size: 70%;
}

.icon-package-tracking {
    background: url(../images/svg/tracking.svg) no-repeat center;
    background-size: 70%;
}

.icon-package-waiting {
    background: url(../images/svg/waiting.svg) no-repeat center;
    background-size: 70%;
}

.icon-package-Booked-doing {
    background: url(../images/svg/Booked-doing.svg) no-repeat center;
    background-size: 70%;
}

.icon-package-pickup-doing {
    background: url(../images/svg/pickup-doing.svg) no-repeat center;
    background-size: 70%;
}

.icon-package-atd-doing {
    background: url(../images/svg/atd-doing.svg) no-repeat center;
    background-size: 70%;
}

.icon-package-arrivedPod-doing {
    background: url(../images/svg/arrivedPod-doing.svg) no-repeat center;
    background-size: 70%;
}

.icon-package-transferOut-doing {
    background: url(../images/svg/transferOut-doing.svg) no-repeat center;
    background-size: 70%;
}

.icon-package-Deliveried-doing {
    background: url(../images/svg/Deliveried-doing.svg) no-repeat center;
    background-size: 70%;
}

.icon-package-cargo {
    background: url(../images/svg/cargo.svg) no-repeat center;
    background-size: 80%;
}

.icon-package-orderTrack {
    background: url(../images/svg/orderTrack.svg) no-repeat center;
    background-size: 80%;
}

.icon-package-subtrackingNo {
    background: url(../images/svg/subtrackingNo.svg) no-repeat center;
    background-size: 80%;
}

.icon-package-subtracking {
    background: url(../images/svg/subtracking.svg) no-repeat center;
    background-size: 80%;
}

.icon-package-checkIn-doing {
    background: url(../images/svg/checkIn-doing.svg) no-repeat center;
    background-size: 70%;
}

.icon-package-checkIn {
    background: url(../images/svg/checkIn.svg) no-repeat center;
    background-size: 70%;
}

.icon-package-docment-doing {
    background: url(../images/svg/docment-doing.svg) no-repeat center;
    background-size: 70%;
}

.icon-package-docment {
    background: url(../images/svg/docment.svg) no-repeat center;
    background-size: 70%;
}

.icon-package-offShelf-doing {
    background: url(../images/svg/offShelf-doing.svg) no-repeat center;
    background-size: 70%;
}

.icon-package-offShelf {
    background: url(../images/svg/offShelf.svg) no-repeat center;
    background-size: 70%;
}

.icon-package-checkOut-doing {
    background: url(../images/svg/checkOut-doing.svg) no-repeat center;
    background-size: 70%;
}

.icon-package-checkOut {
    background: url(../images/svg/checkOut.svg) no-repeat center;
    background-size: 70%;
}

.icon-package-deliveried-doing {
    background: url(../images/svg/delivery-doing.svg) no-repeat center;
    background-size: 80%;
}

.icon-package-deliveried {
    background: url(../images/svg/delivery.svg) no-repeat center;
    background-size: 80%;
}

.icon-package-customRls {
    background: url(../images/svg/customs.svg) no-repeat center;
    background-size: 70%;
}

.icon-package-customRls-doing {
    background: url(../images/svg/customs-doing.svg) no-repeat center;
    background-size: 70%;
}

.icon-package-confirm {
    background: url(../images/svg/confirm.svg) no-repeat center;
    background-size: 70%;
}

.icon-package-confirm-doing {
    background: url(../images/svg/confirm-doing.svg) no-repeat center;
    background-size: 70%;
}

.icon-package-delivery-doing {
    background: url(../images/svg/delivery-doing.svg) no-repeat center;
    background-size: 80%;
}

.icon-package-delivery {
    background: url(../images/svg/delivery.svg) no-repeat center;
    background-size: 80%;
}

.icon-package-podConfirm-doing {
    background: url(../images/svg/podConfirm-doing.svg) no-repeat center;
    background-size: 70%;
}

.icon-package-podConfirm {
    background: url(../images/svg/podConfirm.svg) no-repeat center;
    background-size: 70%;
}

.icon-package-problem {
    background: url(../images/svg/problem.svg) no-repeat center;
    background-size: 70%;
}

.icon-package-express {
    background: url(../images/svg/express.svg) no-repeat center;
    background-size: 70%;
}

.icon-sea-ECPU {
    background: url(../images/svg/sea/container.svg) no-repeat center;
    background-size: 70%;
}

.icon-sea-ECPU-doing {
    background: url(../images/svg/sea/container-doing.svg) no-repeat center;
    background-size: 70%;
}

.icon-sea-GIPOL {
    background: url(../images/svg/sea/ctnTruck.svg) no-repeat center;
    background-size: 70%;
}

.icon-sea-GIPOL-doing {
    background: url(../images/svg/sea/ctnTruck-doing.svg) no-repeat center;
    background-size: 70%;
}

.icon-sea-DPOL {
    background: url(../images/svg/sea/vessel.svg) no-repeat center;
    background-size: 70%;
}

.icon-sea-DPOL-doing {
    background: url(../images/svg/sea/vessel-doing.svg) no-repeat center;
    background-size: 70%;
}

.icon-sea-APOD {
    background: url(../images/svg/sea/vessel.svg) no-repeat center;
    background-size: 70%;
}

.icon-sea-APOD-doing {
    background: url(../images/svg/sea/vessel-doing.svg) no-repeat center;
    background-size: 70%;
}

.icon-sea-GOPOD {
    background: url(../images/svg/sea/ctnTruck.svg) no-repeat center;
    background-size: 70%;
}

.icon-sea-GOPOD-doing {
    background: url(../images/svg/sea/ctnTruck-doing.svg) no-repeat center;
    background-size: 70%;
}

.icon-sea-ECRT {
    background: url(../images/svg/sea/container.svg) no-repeat center;
    background-size: 70%;
}

.icon-sea-ECRT-doing {
    background: url(../images/svg/sea/container-doing.svg) no-repeat center;
    background-size: 70%;
}

.icon-sea-LOAD {
    background: url(../images/svg/sea/crane.svg) no-repeat center;
    background-size: 70%;
}

.icon-sea-LOAD-doing {
    background: url(../images/svg/sea/crane-doing.svg) no-repeat center;
    background-size: 70%;
}

.icon-sea-DIPOD {
    background: url(../images/svg/sea/crane.svg) no-repeat center;
    background-size: 70%;
}

.icon-sea-DIPOD-doing {
    background: url(../images/svg/sea/crane-doing.svg) no-repeat center;
    background-size: 70%;
}

.icon-sea-ctnr {
    background: url(../images/svg/sea/ctnr.svg) no-repeat center;
    background-size: 80%;
}

.icon-sea-port {
    background: url(../images/svg/sea/port.svg) no-repeat center;
    background-size: 70%;
}

.icon-sea-customs {
    background: url(../images/svg/customs.svg) no-repeat center;
    background-size: 70%;
}

.icon-sea-LSTFD {
    background: url(../images/svg/free.svg) no-repeat center;
    background-size: 80%;
}

.icon-sea-CTNHD {
    background: url(../images/svg/hold.svg) no-repeat center;
    background-size: 70%;
}

.icon-sea-RFP {
    background: url(../images/svg/ok.svg) no-repeat center;
    background-size: 70%;
}

.icon-sea-PORTFEE {
    background: url(../images/svg/usd.svg) no-repeat center;
    background-size: 70%;
}

.icon-sea-FPAID {
    background: url(../images/svg/usd.svg) no-repeat center;
    background-size: 70%;
}

.icon-sea-BLMATCH {
    background: url(../images/svg/matched.svg) no-repeat center;
    background-size: 70%;
}

.icon-sea-ISFMATCH {
    background: url(../images/svg/matched.svg) no-repeat center;
    background-size: 70%;
}

.icon-sea-CUSTOMSRLS {
    background: url(../images/svg/customs.svg) no-repeat center;
    background-size: 70%;
}

.icon-sea-CDPOD {
    background: url(../images/svg/customs.svg) no-repeat center;
    background-size: 70%;
}

.icon-sea-EXAM {
    background: url(../images/svg/exam.svg) no-repeat center;
    background-size: 70%;
}

.icon-sea-EXAMTYPE {
    background: url(../images/svg/exam.svg) no-repeat center;
    background-size: 70%;
}

.icon-ds-AU_TRK {
    background: url(../images/svg/sea/vessel.svg) no-repeat center;
    background-size: 70%;
}

.icon-ds-AU_POL {
    background: url(../images/svg/sea/port.svg) no-repeat center;
    background-size: 70%;
}

.icon-ds-AU_POD {
    background: url(../images/svg/sea/port.svg) no-repeat center;
    background-size: 70%;
}

.icon-ds-AU_CUS {
    background: url(../images/svg/customs.svg) no-repeat center;
    background-size: 70%;
}

.icon-info {
    background: url(../images/svg/info.svg) no-repeat center;
    background-size: 75%;
}

.icon-node {
    width: 48px;
    height: 48px;
    margin-right: 0.2rem;
}

.icon-addNode {
    background: url(../images/svg/addNode.svg) no-repeat center;
}

.icon-enterNode {
    background: url(../images/svg/enterNode.svg) no-repeat center;
}

/* user-order/0.0.1 order-process.css Date:2022-10-19 17:28:15 */
.order-process {
    display: inline-block;
    margin: 30px 0 40px;
}


.w-800 {
    width: 800px;
}

.w-1100 {
    width: 1100px;
}

.order-process:after {
    content: ".";
    display: block;
    height: 0;
    clear: both;
    visibility: hidden
}

* html .order-process {
    height: 1%
}

*+html .order-process {
    min-height: 1%
}

.order-process ul {
    position: absolute;
    width: 100%;
    margin-top: -25px;
    text-align: center
}

.order-process .txt1 {
    height: 36px;
    margin-bottom: 30px;
    font-size: 12px
}

.order-process .txt2 {
    font-size: 12px;
    line-height: 25px;
    color: #aaa
}

.order-process .txt3 {
    font-size: 12px;
    color: #aaa;
    line-height: 15px
}

.order-process .node {
    position: relative;
    top: -17px;
    float: left;
    width: 45px;
    height: 43px
}

.order-process .node .node-icon {
    position: absolute;
    display: block;
    left: 1px;
    top: 0;
    width: 43px;
    height: 43px;
    overflow: hidden
}

.h3-icon {
    display: inline-block;
    width: 43px;
    height: 43px;
    overflow: hidden;
    margin-bottom: -12px;
    margin-right: -5px;
}

.order-process .node ul {
    z-index: 1;
    width: 189px;
    margin-left: -70px
}

.order-process .proce {
    position: relative;
    float: left;
    width: 104px;
    height: 9px;
    background: url(../images/svg/proce-icons02.png) no-repeat
}

.order-process .done .txt1 {
    color: #71b247;
    font-weight: 700
}

.order-process .doing .txt1,
.order-process .doing .txt2 {
    color: #00cc0e;
    font-weight: 700
}

.order-process .ready .txt2,
.order-process .ready .txt3 {
    color: #333
}

.order-process .doing {
    background-position: 0 0
}

.order-process .done {
    background-position: 0 -38px
}

.order-process .wait {
    background-position: 0 -19px
}

.bg-doing {
    background-color: #9fffa6;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
}


.table-container {
    width: 100%;
    height: 100%;
    overflow: auto;
    min-height: 120px;
}

/* 表头固定 */
.table-fix-head thead tr>th {
    position: sticky;
    top: 0;
    z-index: 200;
    background-color: #f8f8f8;
}

/* 首列固定 */
.table-fix-col thead tr>th:first-child,
.table-fix-col tbody tr>td:first-child {
    position: sticky;
    left: 0;
    z-index: 1;
    background-color: #f8f8f8;
}

/* 表头首列强制最顶层 */
.table-fix-col thead tr>th:first-child {
    z-index: 3;
}

.input-group-addon:not(:first-child):not(:last-child) .btn,
.input-group-btn:not(:first-child):not(:last-child) .btn,
.input-group .form-control:not(:first-child):not(:last-child) .btn {
    border-radius: 0;
}

.table-text {
    padding: 3px;
    font-size: 12px;
    line-height: 1.66;
    color: #555555;
}

.col-input {
    box-sizing: border-box;
    padding: 0 18px 0 0;
    margin: 0;
    width: 100%;
    border: 1px solid #d4d4d4;
    -webkit-border-top-right-radius: 0px;
    -webkit-border-bottom-right-radius: 0;
    -webkit-border-bottom-left-radius: 0;
    -webkit-border-top-left-radius: 0;
    -moz-border-radius-topright: 0px;
    -moz-border-radius-bottomright: 0;
    -moz-border-radius-bottomleft: 0;
    -moz-border-radius-topleft: 0;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    -moz-background-clip: padding-box;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
}

.text-drop {
    padding: 0px;
    font-size: 13px;
}

.label-xs {
    padding: 0px 2px;
    margin-left: 4px;
}

.title-txt {
    color: #777777;
    margin-left: 5px;
    vertical-align: sub;
}

.row-title {
    background: #c9c9c9;
    text-align: center;
    font-size: 12px;
    height: 25px;
    padding-top: 5px;
    border-radius: 2px;
    line-height: 15px;
    font-weight: 700 !important;
}

.ops-panel-title {
    border-radius: 2px;
    background-color: #bdbdbd;
    -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
    margin: 10px 0 0;
    padding: 8px 8px 6px 8px;
    font-size: 13px;
    color: #4d4d4d;
    line-height: 15px;
    font-weight: 700 !important;
}

.form-group textarea {
    max-width: 100%;
}

li a em {
    color: #777777;
}

.pl-row {
    margin-left: -2px;
    margin-right: -2px;
}

.bk-title {
    border-bottom: solid 1px #d0dee5;
    line-height: 10px;
    position: relative;
    height: 10px;
    margin-bottom: 10px;
    padding-top: 60px;
    padding-right: 5px;
    background-color: #f8f8f8;
}

.bk-title>span {
    font-weight: bold;
    font-size: 15px;
    border-bottom: solid 3px #5bc0de;
    float: left;
    position: absolute;
    bottom: -1px;
    padding: 0 3px;
    height: 30px;
    line-height: 30px;
}
.bk-title>span>span {
    padding-left: 5px;
}

.bk-title>small,
.role-title>small {
    color: #5491a2;
    float: right;
    margin-top: -16px;
}


.bk-title>span>small,
.role-title>span>small {
    color: #5491a2;
    margin-left: 7px;
}

.bk-title>span>small:hover,
.bk-title>span small button:hover,
.role-title>span>small:hover,
.role-title>span small button:hover {
    color: #337ab7;
    text-decoration: none;
    /*    cursor: pointer;*/
}

.bk-title>span>small button,
.role-title>span>small button {
    color: #396f7d;
    padding: 1px 3px;
}

.role-title {
    border-bottom: solid 1px #d0dee5;
    line-height: 10px;
    position: relative;
    height: 10px;
    margin-bottom: 3px;
    margin-top: 50px;
}

.role-title>span {
    font-weight: bold;
    font-size: 14px;
    border-bottom: solid 3px #5bc0de;
    float: left;
    position: absolute;
    bottom: -1px;
    padding: 0 3px;
    height: 30px;
    line-height: 30px;
}

.role-title-margin {
    margin-top: -20px;
}

.job-bg {
    padding-top: 10px;
    padding-bottom: 10px;
    border-top: 1px solid #e5e5e5;
    border-bottom: 1px solid #e5e5e5;
    background-color: #eeeeee;
}

.inline-mw-400 {
    display: inline-block;
    max-width: 400px;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-left: 10px;
    color: #777777;
    margin-bottom: -5px;
}

.side-bar {
    position: fixed;
    top: 0;
    right: 0;
    width: 350px;
    height: 100%;
    background-color: #f5f5f5;
    z-index: 9;
}

.side-bar-nav1 ul li {
    color: #5a5a5a;
    display: block;
    padding: 1em 5%;
    background-color: #e7e7e7;
    border: 1px solid #c9c9c9;
}

.side-bar-nav {
    height: 100%;
    border: 1px solid #c9c9c9;
    border-top: none;
    overflow-y: auto;
    overflow-x: hidden;
    scrollbar-width: none;
    -ms-overflow-style: none;
}

.side-bar-nav ul li {
    display: block;
    padding: 1em 5%;
    border-bottom: 1px solid #e7e7e7;
    transition: all 0.2s ease-out;
}

.side-bar-nav ul li:last-child {
    border-bottom: none;
}


.side-bar-nav::-webkit-scrollbar {
    display: none;
}

.side-bar-nav ul li a {
    color: #5a5a5a;
}

.side-bar-nav ul li a small {
    color: gray;
}

.side-bar-nav ul li:hover {
    background-color: #e7e7e7;
    border-bottom: 1px solid #c9c9c9;
}

.side-btn {
    position: fixed;
    right: 0;
    width: 35px;
    height: 100%;
    z-index: 9;
    text-align: center;
}

/*侧滑关键*/
.side-btn-icon {
    height: 35px;
    line-height: 35px;
    width: 35px;
    background-color: #e7e7e7;
    color: #5a5a5a;
    display: inline-block;
    text-align: center;
}

.nav-btn {
    position: absolute;
    right: 20px;
    bottom: 80px;
}

.worktable-head {
    margin-right: -2px;
    margin-top: 7px;
    margin-bottom: -10px;
    border: 1px solid #d4d4d4;
    border-bottom: none;
    padding: 10px;
    background-color: #eeeeee;
    font-size: 13px;
    color: #666;
}

.worktable-head .work-node {
    color: #777777 !important;

}

.ops-txt .txt-left {
    width: 25%;
    font-weight: 600;
    color: #606161;
    position: relative;
    float: left;
    min-height: 1px;
    text-align: right;
    font-size: 13px;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    white-space: nowrap;
    -ms-text-overflow: ellipsis;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    overflow: hidden;
}

.ops-txt .txt-right {
    width: 75%;
    font-weight: 500;
    color: #777777;
    position: relative;
    float: left;
    min-height: 1px;
    padding-left: 10px;
    padding-right: 0px;
    font-size: 13px;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    white-space: nowrap;
    -ms-text-overflow: ellipsis;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    overflow: hidden;
}

.txt-inline {
    position: relative;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    white-space: nowrap;
    -ms-text-overflow: ellipsis;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    overflow: hidden;
}

.txt-area {
    padding-top: 15px;
    padding-bottom: 15px;
    border: 1px solid #e5e5e5;
    background-color: #f5f5f5;
    border-radius: 2px;
}

.cur-ttl {
    margin-right: 8px;
    font-size: 14px;
    color: #777777;
    font-weight: 600;
}

.ops-txt .txt-top {
    font-weight: 600;
    color: #666666;
    text-align: center;
    font-size: 30px;

}

.ops-txt .txt-bottom {
    color: #666666;
    text-align: center;
    font-size: 13px;
}

.ops-txt .txt-head {
    font-weight: 600;
    color: #666666;
    position: relative;
    min-height: 1px;
    font-size: 13px;

}

.ops-txt .txt-body {
    font-weight: 500;
    color: #777777;
    position: relative;
    min-height: 1px;
    font-size: 13px;
}

.ui-grid-cell .label-primary,
.ui-grid-cell .label-danger,
.ui-grid-cell .label-success,
.ui-grid-cell .label-info {
    margin-top: 7px;
    display: inline-block;
}

.ui-grid-cell .grid-text .label-primary,
.ui-grid-cell .grid-text .label-danger,
.ui-grid-cell .grid-text .label-success,
.ui-grid-cell .grid-text .label-info {
    margin-top: 0px;
}

.ui-grid-cell .btn-xs {
    margin-top: 4px;
}

.ui-grid-cell .flex-btn-group-container {
    margin-right: 3px;
}

@media screen and (max-width:450px) {
    .xxs-hidden {
        display: none;
    }
}
.append-to-body{
    overflow: visible !important;
}
.ui-grid-cell:has(.append-to-body){
    overflow: visible !important;
}

.p1-card__title {
    text-transform: uppercase;
    line-height: 1;
    margin-bottom: 0.5rem;
    font-weight: bold;
    white-space: nowrap;
}
.p1-divider {
    font-weight: normal;
    color: #d1d3d4;
    margin-left: 2px;
    margin-right: 2px;
    line-height: 1;
}
.p1-text--regular {
    font-weight: normal !important;
}

.p1-list-key-value {
    list-style: none;
    margin: 0;
    padding: 0;
}
.p1-list-key-value__row {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    position: relative;
    margin-bottom: 0.155rem;
    z-index: 0;
}
.p1-list-key-value__key, .p1-list-key-value__value {
    white-space: nowrap;
    padding-bottom: 0.125rem;
}
.p1-list-key-value__key {
    text-transform: none;
    font-weight: bold;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 0;
    padding-right: 0.125rem;
}
.p1-list-key-value__value {
    text-align: right;
    padding-left: 0.125rem;
    font-weight: normal !important;
    border: none;
}
.modal-uib-mt{
    margin-top: 28px;
}

.bk-title>small>label>input {
    margin-top: -2px;
}
.table-pre-text{
    background-color: inherit;
    border: none;
    padding: 0px;
    max-width: 500px;
    white-space:pre-wrap;
    white-space:-moz-pre-wrap;
    white-space:-pre-wrap;
    white-space:-o-pre-wrap;
    word-wrap:break-word;
}

